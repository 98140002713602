import React from 'react';
import classes from "./Backdrop.module.css";

let Backdrop = (props) => {

    let backDropClass = [classes.Backdrop];

    if (props.center) {
        backDropClass.push(classes.Center);
    }

    if (props.show) {
        backDropClass.push(classes.Show);
    }

    const handleClick = () => {
        if (props.sideDrawer) {
            props.sideDrawerHandler(false);
        }

        if (props.clickable) {
            props.clickHandler(false);
        }
    }

    return (
        <div className={backDropClass.join(" ")} onClick={handleClick} style={props.style ? props.style : {}}>
            {props.children}
        </div>
    );
}


export default Backdrop;
