import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useGlobal from '../../../../components/Hooks/useGlobal';
import Axios from 'axios';
import { transformDateOnlyHour } from '../../../../components/Helper/DateHelper';

  
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    table: {
      minWidth: 650,
    },
}));
const AdminDashboard = (props) =>{
    const [clientCount, setClientCount] = useState(0);
    const [projectCount, setProjectCount] = useState(0);
    const [globalState, globalActions] = useGlobal();
    
    const getClientCount = () =>{
        Axios.get(
        `${process.env.REACT_APP_SOCKET}/api/count/${process.env.REACT_APP_KEY}/${globalState.userCookies}`,
          {
            headers: globalState.httpReqHeaders
          }
        ).then((response)=>{
          const data = response.data.payload
          setClientCount(data)
        }).catch((err)=>{
          setClientCount(0)
        
        })
    }

    const getProjectCount = async() =>{
        try {
            let data = await Axios.get(
            `${process.env.REACT_APP_SCHEDULER_PROJECT_API}user/project/count`,
            {
              headers: globalState.httpReqHeaders
            }
            );
            let payload = data.data.payload;
            setProjectCount(payload);
        } catch (e) {
            setProjectCount(0);
        }
    }
    
   
    useEffect(()=>{
        globalState.socket.on("getClient", data =>{            
            getClientCount()
        })    
        getClientCount();
        getProjectCount();
        // getSchedules();
    }, [])
    
  const classes = useStyles();
    return(
        <>
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Paper className={classes.paper}>
                        <h2>Active Project(s):</h2>
                        <h2>{projectCount}</h2>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper className={classes.paper}>
                        <h2>Connected Client(s):</h2>
                        <h2>{clientCount}</h2>
                    </Paper>
                </Grid>  
                <Grid item xs={12}>
                    <Typography  variant="h6" component="div">
                        Today's Schedule(s):
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="schedule table">
                            <TableHead>
                            <TableRow>
                                <TableCell><b>Activity</b></TableCell>
                                <TableCell align="right"><b>Time</b></TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {globalState.schedules.map((row) => (
                                <TableRow key={row.text}>
                                <TableCell component="th" scope="row">
                                    {row.text}
                                </TableCell>
                                <TableCell align="right">
                                    {transformDateOnlyHour(new Date(row.ts))}</TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    
                </Grid>              
            </Grid>
        </div>
        </>
    )

}

export default AdminDashboard;