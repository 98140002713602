import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useGlobal from '../../../../../components/Hooks/useGlobal';

const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
      color: '#fff'
    },
    red:{
      backgroundColor: '#b0261c',
    },
    green:{
      backgroundColor: '#388c46',
    },
    blue:{
      backgroundColor: '#3289a8',
    },
    grey:{
      backgroundColor: '#72858c',
    },
  }));

const PCStatus = (props) =>{
    const classes = useStyles();
    const [globalState, globalActions] = useGlobal();
    const [state, setState] = useState('disconnect');
    useState(()=>{
        globalState.socket.emit('hello', `${props.base}.${props.ip+100}`);
        globalState.socket.on(`web-${props.base}.${props.ip+100}-running`, data=>{
          setState('running');
        });
        globalState.socket.on(`web-${props.base}.${props.ip+100}-error`, data=>{
          setState('error');
        });
        globalState.socket.on(`web-${props.base}.${props.ip+100}-finished`, data=>{
          setState('finished');
        });
        globalState.socket.on(`reply-${props.base}.${props.ip+100}`, data=>{
          setState('idle');
        });
    }, []);
    return (
        <Button 
        variant="contained" 
        size="large"
        disabled={state=='disconnect' ? true : false}
        className={classes.margin + ' ' + (state == 'finished' ? classes.green : state == 'running' ? classes.blue : state == 'error' ? classes.red : classes.grey)}
        onClick={()=>{
            globalState.socket.emit(`patch`, `${props.base}.${props.ip+100}`, props.slug, data =>{
                console.log(data);
            });
        }}
        >
            {props.ip}
        </Button>
    );
};

export default PCStatus;