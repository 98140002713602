import React from 'react'
import swal from 'sweetalert';

const Message = (props) =>{
    swal({
        text: props.text,
        icon: props.icon,
        timer: props.timer || 10000,
        button: "Close",
      }).then(
        props.handler("","",false)
      );
    return(<div></div>);
}

export default Message;