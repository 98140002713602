import React, { useState, useEffect } from 'react'
import Backdrop from "../Backdrop/Backdrop"
import ReactLoading from 'react-loading';
import classes from "./Loading.module.css";

const Loading = (props) =>{
    return(
    <Backdrop center={true} show={true} style={{zIndex: '100000'}}>
        <ReactLoading className={classes.Center} type={"bars"} color={"#fff"} height={100} width={100} />
    </Backdrop>

    );
}

export default Loading;