import React, { useState, useEffect } from "react";
import { Paper, Button, Divider, Grid } from "@material-ui/core";
import classes from "../ProjectManager.module.css";
import Table from "../../../../components/UI/Table/Table";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import useGlobal from "../../../../components/Hooks/useGlobal";
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { transformDate } from '../../../../components/Helper/DateHelper';
import Axios from 'axios';

const SubProjectList = (props)=>{
  const [globalState, globalActions] = useGlobal();
  const [isLoad, setIsLoad] = useState(false);
  const [subProject, setSubProject] = useState([]);
  const [isOwner, setIsOwner] = useState(false)
;
  const additionalData = {
    id:props.data._id,
    groups : props.data.schedule.groups,
    users:props.data.schedule.users
  }

  const columns = [
    { 
      title: 'Deadline', 
      field: 'schedule.ts',
      type: 'datetime',
      render: rowData =>{
        if(rowData.schedule === null || rowData.schedule.ts === null){
          return '-'
        }
        return(
          transformDate(new Date(rowData.schedule.ts))
        )
        
      },
      cellStyle: {
        width: '30%',
      },
      headerStyle: {
        width: '30%',
      }
    },
    { 
      title: 'Sub Project Name',
      field: 'schedule.text',
    },
    { 
      title: 'Description',
      field: 'description',
    },
    { 
      title: 'Sub Project Status',
      field: 'status',
      lookup: {'Development':'Development', 'Production':'Production', 'Canceled':'Canceled'},
    },
  ]
  

  useEffect(() => {
    if(subProject.length === 0){
        fetchSubProject(props.data.key);
    }
  }, []);

  const insertSubProject = async(evt)=>{

    const postData = await{
      id: additionalData.id,
      title: evt.schedule.text,
      description: evt.description,
      date: evt.schedule.ts,
      groups:additionalData.groups,
      users:additionalData.users,
      status: evt.status
    }
    setIsLoad(true);
    try {
      Axios.post(
        `${process.env.REACT_APP_SCHEDULER_PROJECT_API}user/project/subproject/add`,
        postData,
        {
          headers: globalState.httpReqHeaders
        }
      ).then((res)=>{
        fetchSubProject(props.data.key);
      }).catch(error => {
        if (!error.response) {
          props.messageHandler(
            "Unexpected error, please contact the administrator",
            "error",
            true
          );
        } else {
          props.messageHandler(
            "Error (" +
              error.response.status +
              "): Unexpected error, please contact the administrator",
            "error",
            true
          );
      }
      })
    
    } catch (error) {
      props.messageHandler(
        "Unexpected error, please contact the administrator",
        "error",
        true
      );
    } finally{
      setIsLoad(false);
    }
  }
  
  const fetchSubProject = async (appKey) => {
    setIsLoad(true);
    let data;
    try {
        data = await Axios.get(
        `${process.env.REACT_APP_SCHEDULER_PROJECT_API}user/project/get/with-sub/${appKey}`,
        {
          headers: globalState.httpReqHeaders
        }
        );
        let payload = data.data.payload;        
        if(payload.length != 0 && payload[0].schedule.owner === props.userData.id && payload[0].status !="Canceled"){
          setIsOwner(true);
        }
        setSubProject(payload);
    } catch (e) {
        props.messageHandler("Failed to load data", "error", true);
        setSubProject([]);
    }
    setIsLoad(false);
  };

  const updateSubProject = async(data) =>{
    const postData = await{
      projectId: additionalData.id,
      id: data._id,
      title: data.schedule.text,
      description: data.description,
      date: data.schedule.ts,
      schedule: data.schedule._id,
      groups:additionalData.groups,
      users:additionalData.users,
      status: data.status
    }
    setIsLoad(true);
    try {
      Axios.patch(
        `${process.env.REACT_APP_SCHEDULER_PROJECT_API}user/project/subproject/update`,
        postData,
        {
          headers: globalState.httpReqHeaders
        }
      ).then((res)=>{
        fetchSubProject(props.data.key);
      }).catch(error => {
        if (!error.response) {
          props.messageHandler(
            "Unexpected error, please contact the administrator",
            "error",
            true
          );
        } else {
          props.messageHandler(
            "Error (" +
              error.response.status +
              "): Unexpected error, please contact the administrator",
            "error",
            true
          );
      }
      })
    
    } catch (error) {
      props.messageHandler(
        "Unexpected error, please contact the administrator",
        "error",
        true
      );
    } finally{
      setIsLoad(false);
    }
  }

    return(
        <Table 
        columns={columns}
        isLoad={isLoad}
        detail={false}
        // components={components}
        editable={isOwner}
        data={subProject}      
        title="Sub Project"
        search={false}
        insertable={props.data.schedule.owner == props.userData.id }
        insertHandler={insertSubProject}
        updateHandler={updateSubProject}
      />
    )
}

export default SubProjectList