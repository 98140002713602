import React from 'react';
import globalHook from 'use-global-hook';
 
const initialState = {
  httpReqHeaders : null,
  userCookies: null,
  socket: null,
  schedules: [],
  date: '',
};
 
const actions = {
  addHTTPReqHeaders: (store, cookies) => {
    store.setState({  
        httpReqHeaders : {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `jwt ${cookies}`
        }
    });
  },
  addUserCookies: (store, cookies) => {
    store.setState({  
      userCookies : cookies
    });
  },
  addSocket: (store, socket) => {
    store.setState({  
      socket : socket
    });
  },
  setSchedules: (store, data) =>{
    store.setState({
      schedules: data
    })
  },
  setDate: (store, date) =>{
    store.setState({
      date: date
    })
  },
  

};
 
const useGlobal = globalHook(React, initialState, actions);

export default useGlobal