import React, { useState, useEffect } from "react";
import RegisterForm from "./RegisterForm/RegisterForm";
import Message from "../../../../../components/UI/Message/Message";
import Loading from "../../../../../components/UI/Loading/Loading";
import Axios from "axios";

const RegisterContainer = props => {
  const [formData, setFormData] = useState({
    userName: "",
    password: "",
    roleId: "",
    organizationId: "",
    isSubmit: false
  });

  const [message, setMessage] = useState({
    text: "",
    type: "",
    status: false
  });

  const messageHandler = (text, type, state) => {
    let newMessage = { ...message };
    newMessage.text = text;
    newMessage.type = type;
    newMessage.status = state;
    setMessage(newMessage);
  };

  let msg = message.status ? (
    <Message text={message.text} icon={message.type} handler={messageHandler} />
  ) : (
    ""
  );

  const changeIsSubmit = status => {
    let prevData = { ...formData };
    prevData.isSubmit = status;
    setFormData(prevData);
  };
  const validateForm = (username, password, roleId, organizationId) => {
    if (username.length === 0) {
      messageHandler("Username can't be empty", "error", true);
      return false;
    } else if (password.length === 0) {
      messageHandler("Password can't be empty", "error", true);
      return false;
    } else if (organizationId.length === 0) {
      messageHandler("Organization can't be empty", "error", true);
      return false;
    } else if (roleId.length === 0) {
      messageHandler("Role can't be empty", "error", true);
      return false;
    }

    return true;
  };

  const onClickSubmit = async evt => {
    evt.preventDefault();
    changeIsSubmit(true);
    if (
      !validateForm(
        formData.userName,
        formData.password,
        formData.roleId,
        formData.organizationId
      )
    ) {
      changeIsSubmit(false);
      return;
    }
    let userName = formData.userName;
    let payload = "";
    var b = new Buffer(formData.password);
    payload = b.toString("base64");
    let registerResult;
  
    try {
      registerResult = await Axios.post(process.env.REACT_APP_DEFAULT_API + 'register', {
            username: userName,
            password: payload,
            roleId: formData.roleId,
            organizationId: formData.organizationId
        });
    } catch (error) {
        if(!error.response){
            messageHandler("Unexpected error, please contact the administrator", "error", true);
        } else {
            messageHandler("Error (" + error.response.status + "): " + error.response.data.message, "error", true);
        }

        changeIsSubmit(false);
        return;
    }
    if (registerResult.data) {
      if (!registerResult.data.success) {
        messageHandler(
          "Error (" +
            registerResult.data.status +
            "): " +
            registerResult.data.message,
          "error",
          true
        );
        changeIsSubmit(false);
        return;
      }
      messageHandler("New user added", "success", true);
      props.setSelectedMenu(0);
    }

    changeIsSubmit(false);
  };

  const setFormDataHandler = (type, data) => {
    let newState = { ...formData };
    if (type === "username") {
      newState.userName = data;
    } else if (type === "password") {
      newState.password = data;
    } else if (type === "organization") {
      newState.organizationId = data;
    } else if (type === "role") {
      newState.roleId = data;
    }

    setFormData(newState);
  };

  let loading = formData.isSubmit ? <Loading /> : "";

  return (
    <>
      {loading}
      {msg}
      <RegisterForm
        form={formData}
        message={message}
        setFormDataHandler={setFormDataHandler}
        onClickSubmit={onClickSubmit}
        history={props.history}
        {...props}
      />
    </>
  );
};

export default RegisterContainer;
