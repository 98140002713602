import React from 'react'
import { NavLink } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import classes from './MainMenuItem.module.css';
import ItemIcon from './ItemIcon/ItemIcon'

const MainMenuItem = (props) =>{
    const menu = props.menu.map((item, index) => {
        return(
            <NavLink exact={props.exact} to={item.link} className={classes.NavLink} activeClassName={classes.Active} key={index}>
                <div>
                    <ListItem button key={index}>                
                        <ListItemIcon ><ItemIcon iconName={item.icon}/></ListItemIcon>
                        <ListItemText primary={item.text} />
                    </ListItem>
                </div>
            </NavLink>
        )
    });

    return (
        <List>
          {menu}
        </List>
    )
}

export default MainMenuItem;