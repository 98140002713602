import React from "react";
import {
  Card,
  Typography,
  Divider,
  CardContent,
  Grid,
  TextField,
  Button
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TitleIcon from "@material-ui/icons/Title";
import TextEditor from "../../../components/UI/TextEditor/TextEditor";

const DocumentationForm = props => {
  return (
    <>
      <Card variant="outlined">
        <CardContent>
          <Typography color="textPrimary" gutterBottom>
            Insert Documentation
          </Typography>
          <Divider style={{ marginBottom: "10px" }} />
          <form method="post" onSubmit={props.onClickSubmit}>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item>
                <TitleIcon />
              </Grid>
              <Grid item md={true} sm={true} xs={true}>
                <TextField
                  id="title"
                  label="Documentation Title"
                  type="text"
                  fullWidth
                  autoFocus
                  required
                  onChange={e => {
                    props.setTitle(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              alignItems="flex-end"
              style={{ marginTop: "20px" }}
            >
              <TextEditor {...props} setContent={props.setContent} />
            </Grid>
            <Grid container justify="center" style={{ marginTop: "20px" }}>
              <Button
                variant="outlined"
                color="primary"
                style={{ textTransform: "none" }}
                type="submit"
                fullWidth
              >
                Insert
              </Button>
            </Grid>
          </form>
        </CardContent>
        <Button
          size="medium"
          style={{ marginTop: "30px" }}
          color="secondary"
          onClick={e => props.setSelectedMenu(0)}
        >
          <ArrowBackIcon />
          Back
        </Button>
      </Card>
    </>
  );
};

export default DocumentationForm;
