import React, { useState } from "react";
import Tabular from "../../../components/UI/Tabular/Tabular";
import Message from "../../../components/UI/Message/Message";
import ScheduleList from "./ScheduleList/ScheduleList";
import useGlobal from "../../../components/Hooks/useGlobal";
import ScheduleHistory from "./ScheduleList/ScheduleHistory";
import { transformDate } from '../../../components/Helper/DateHelper';

const UserManager = props => {
  const [selectedMenu, setSelectedMenu] = useState(0);
  const [globalState, globalActions] = useGlobal();
  const [message, setMessage] = useState({
    text: "",
    type: "",
    status: false
  });
  const tabMenu = ["Next Schedule", "All Schedule"];

  const columns = [
    { 
      title: 'Date', 
      field: 'ts',
      type: 'datetime',
      render: rowData =>{
        return(
          transformDate(new Date(rowData.ts))
        )
        
      },
      cellStyle: {
        width: '30%',
      },
      headerStyle: {
        width: '30%',
      }
    },
    { 
      title: 'Activity',
      field: 'text',
      cellStyle: {
        width: '70%',
      },
      headerStyle: {
        width: '70%',
      }
    }
  ]


  const messageHandler = (text, type, state) => {
    let newMessage = { ...message };
    newMessage.text = text;
    newMessage.type = type;
    newMessage.status = state;
    setMessage(newMessage);
  };

  let msg = message.status ? (
    <Message text={message.text} icon={message.type} handler={messageHandler} />
  ) : (
    ""
  );

  const renderBody = () => {
    if (selectedMenu == 0) {
      return (
        <ScheduleList
        {...props}
        transformDate={transformDate}
        columns={columns}
        globalState={globalState}
        messageHandler={messageHandler}
      ></ScheduleList>
      );
    } else if (selectedMenu == 1) {
      return (
        <ScheduleHistory
          {...props}
          transformDate={transformDate}
          columns={columns}
          messageHandler={messageHandler}
        ></ScheduleHistory>
      );
    } 
  };

  return (
    <>
      {msg}
      <Tabular tabMenu={tabMenu} setSelectedMenu={setSelectedMenu} />
      {renderBody()}
    </>
  );
};

export default UserManager;
