import React, {useState} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import AdminDashboard from './AdminDashboard/AdminDashboard';
import UserManager from './UserManager/UserManager';
import DocumentationContainer from '../../GeneralFeatures/Documentation/DocumentationContainer'
import DocumentationDetails from '../../GeneralFeatures/Documentation/DocumentationDetails'
import ScheduleContainer from '../../GeneralFeatures/ScheduleManager/ScheduleContainer';
import ProjectManager from '../../GeneralFeatures/ProjectManager/ProjectManager';
import MediaManager from './MediaManager/MediaManager';
import SocketManagerContainer from './SocketManager/SocketManagerContainer';
import RoomManagerContainer from '../../GeneralFeatures/RoomManager/RoomManagerContainer';
import PatchManager from '../../GeneralFeatures/PatchManager/PatchManager';
import PatchDetails from '../../GeneralFeatures/PatchManager/PatchDetails/PatchDetails';
import RoomDetails from '../../GeneralFeatures/PatchManager/PatchDetails/RoomDetails/RoomDetails';
import LogOut from '../../LogOut';

const AdminHome = (props) => {

    return(
        <Switch>
            <Route path="/home/admin/dashboard" render={(_) => 
            <AdminDashboard {..._} userCookies={props.userCookies}/>}/>
            <Route path="/home/admin/user-manager" render={(_) => <UserManager userData={props.userData} {..._} userCookies={props.userCookies}/>}/>
            <Route path="/home/documentation" exact render={(_) => <DocumentationContainer userData={props.userData} {..._} userCookies={props.userCookies}/>}/>
            <Route path="/home/documentation/:slug" render={(_) => <DocumentationDetails messageHandler={props.messageHandler} userData={props.userData} {..._} userCookies={props.userCookies} userRole="user"
            url={process.env.REACT_APP_DOCUMENTATION_API}
            section="documentations"/>}/>
            <Route path="/home/admin/schedule-manager" render={(_) => <ScheduleContainer userData={props.userData} {..._} userCookies={props.userCookies}/>}/>
            <Route path="/home/admin/patch-manager" exact render={(_) => <PatchManager userData={props.userData} {..._} userCookies={props.userCookies}  messageHandler={props.messageHandler}/>}/>
            <Route path="/home/admin/patch-manager/:slug" exact render={(_) => <PatchDetails userData={props.userData} {..._} userCookies={props.userCookies}  messageHandler={props.messageHandler}/>}/>
            <Route path="/home/admin/patch-manager/:slug/:roomSlug" render={(_) => <RoomDetails userData={props.userData} {..._} userCookies={props.userCookies}  messageHandler={props.messageHandler}/>}/>
            <Route path="/home/admin/project-manager" render={(_) => <ProjectManager userData={props.userData} {..._} userCookies={props.userCookies}/>}/>
            <Route path="/home/admin/media-manager" render={(_) => <MediaManager userData={props.userData} {..._} userCookies={props.userCookies}/>}/>
            <Route path="/home/admin/socket-manager" render={(_) => <SocketManagerContainer userData={props.userData} {..._} userCookies={props.userCookies}/>}/>
            <Route path="/home/admin/room-manager" render={(_) => <RoomManagerContainer userData={props.userData} {..._} userCookies={props.userCookies}/>}/>
            <Route path="/home/log-out" render={(_) => <LogOut/>} />
            {/* {/* <Route path="/home/staff/settings" render={(_) => <Settings {..._} {...props} loadingHandler={setIsLoad}/>}/>
            <Route path="/home/staff/feedback" render={(_) => <Feedback {..._} {...props} loadingHandler={setIsLoad}/>}/> */}
            {/* <Route path="/home/log-out" render={(_) => <LogOut messageHandler={props.messageHandler} />}/> */}
            <Redirect to="/home/admin/dashboard"/>
        </Switch>
    )
}

export default AdminHome;