import React, { useState, useEffect } from "react";
import DataTable from "../../../../../components/UI/DataTable/DataTable";

const OrganizationManager = props => {

  const [columns, setColumns] = useState([
    { title: "Organization Name", field: "organizationName" }
  ]);

  return (
    <>
      <DataTable {...props} section='organization' userRole='admin' 
        title="Organization Manager" columns={columns} editable={true}
            insertable={true}></DataTable>
    </>
  );
};

export default OrganizationManager;
