import React, { useState } from "react";
import Message from "../../../components/UI/Message/Message";
import DocumentationForm from "./DocumentationForm";
import DocumentationLists from "./DocumentationLists";
import Axios from "axios";
import Loading from "../../../components/UI/Loading/Loading";
import DocumentationDetails from "./DocumentationDetails";
import {Link} from 'react-router-dom';
import UpdateDocumentation from "./UpdateDocumentation";

const DocumentationContainer = props => {
  const httpReqHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `jwt ${props.userCookies}`
  };

  const [selectedMenu, setSelectedMenu] = useState(0);

  const [formData, setFormData] = useState({
    content: "",
    title: "",
    isSubmit: false
  });

  const [detailData, setDetailData] = useState({
    id: null
  });

  const [message, setMessage] = useState({
    text: "",
    type: "",
    status: false
  });

  const uploadImageHandler = async file => {
    try {
      const data = file//new FormData();
      // data.append("media", file);
      // data.append("app_key", process.env.REACT_APP_KEY)
      const image = await Axios.post(
        process.env.REACT_APP_MEDIA_API + "media/add",
        data,
        {
          headers: httpReqHeaders
        }
      );
      return new Promise((resolve, reject) => {
        resolve({
          data: {
            link: image.data.url
          }
        });
      });
    } catch (error) {
      if (!error.response) {
        messageHandler(
          "Unexpected error, please contact the administrator",
          "error",
          true
        );
      } else {
        messageHandler(
          "Error (" +
            error.response.status +
            "): " +
            error.response.data.message,
          "error",
          true
        );
      }
    }
  };

  const messageHandler = (text, type, state) => {
    let newMessage = { ...message };
    newMessage.text = text;
    newMessage.type = type;
    newMessage.status = state;
    setMessage(newMessage);
  };

  let msg = message.status ? (
    <Message text={message.text} icon={message.type} handler={messageHandler} />
  ) : (
    ""
  );

  const changeIsSubmit = status => {
    let prevData = { ...formData };
    prevData.isSubmit = status;
    setFormData(prevData);
  };

  const setContent = content => {
    let prevData = { ...formData };
    prevData.content = content;
    setFormData(prevData);
  };

  const setTitle = title => {
    let prevData = { ...formData };
    prevData.title = title;
    setFormData(prevData);
  };

  const setSlug = slug => {
    let prevData = { ...detailData };
    prevData.slug = slug;
    setDetailData(prevData);
  };

  const validateForm = () => {
    if (formData.title.length === 0) {
      messageHandler("Title Can't Be Empty", "error", true);
      return false;
    } else if (formData.content.length == 0) {
      messageHandler("Documentation Content Can't Be Empty", "error", true);
      return false;
    } else if (detailData.id !== null && detailData.id.length === 0 && selectedMenu === 4) {
      return false;
    }
    return true;
  };

  const insert = async evt => {
    let insertDoc;

    try {
      insertDoc = await Axios.post(
        process.env.REACT_APP_DOCUMENTATION_API + "user/documentations/add",
        {
          title: formData.title,
          content: formData.content
        },
        {
          headers: httpReqHeaders
        }
      );
    } catch (error) {
      if (!error.response) {
        messageHandler(
          "Unexpected error, please contact the administrator",
          "error",
          true
        );
      } else {
        messageHandler(
          "Error (" +
            error.response.status +
            "): " +
            error.response.data.message,
          "error",
          true
        );
      }

      changeIsSubmit(false);
      return;
    }
    if (insertDoc.data) {
      if (!insertDoc.data.status) {
        messageHandler(
          "Error (" + insertDoc.data.status + "): " + insertDoc.data.message,
          "error",
          true
        );
        changeIsSubmit(false);
        return;
      }
      messageHandler("New documentation added", "success", true);
    }
  };

  const update = async evt => {
    let updateDoc;

    try {
      updateDoc = await Axios.patch(
        process.env.REACT_APP_DOCUMENTATION_API + "user/documentations/update",
        {
          slug: detailData.slug,
          title: formData.title,
          content: formData.content
        },
        {
          headers: httpReqHeaders
        }
      );
    } catch (error) {
      if (!error.response) {
        messageHandler(
          "Unexpected error, please contact the administrator",
          "error",
          true
        );
      } else {
        messageHandler(
          "Error (" +
            error.response.status +
            "): " +
            error.response.data.message,
          "error",
          true
        );
      }

      changeIsSubmit(false);
      setSlug(null);
      return;
    }
    if (updateDoc.data) {
      if (!updateDoc.data.status) {
        messageHandler(
          "Error (" + updateDoc.data.status + "): " + updateDoc.data.message,
          "error",
          true
        );
        changeIsSubmit(false);
        setSlug(null);
        return;
      }
      messageHandler("Documentation Updated", "success", true);
    }
  };

  const onClickSubmit = async evt => {
    evt.preventDefault();
    changeIsSubmit(true);
    if (!validateForm()) {
      changeIsSubmit(false);
      return;
    }
    if (selectedMenu == 3) {
      insert();
    } else if (selectedMenu == 4) {
      update();
    }
    setSelectedMenu(0);
    setSlug(null);
    changeIsSubmit(false);
  };

  let loading = formData.isSubmit ? <Loading /> : "";

const renderBody = () => {
    if (selectedMenu == 0) {
      return (
        <DocumentationLists
          {...props}
          messageHandler={messageHandler}
          setSelectedMenu={setSelectedMenu}
          insertFreeAction={true}
          updateFreeAction={true}
          slugHandler={setSlug}
        ></DocumentationLists>
      );
    } else if (selectedMenu == 3) {
      return (
        <DocumentationForm
          {...props}
          messageHandler={messageHandler}
          setSelectedMenu={setSelectedMenu}
          setContent={setContent}
          setTitle={setTitle}
          onClickSubmit={onClickSubmit}
          imageHandler={uploadImageHandler}
        />
      );
    } else if (selectedMenu == 4) {
      //update
      return (
        <UpdateDocumentation
          {...props}
          messageHandler={messageHandler}
          slugHandler={setSlug}
          setContent={setContent}
          setTitle={setTitle}
          imageHandler={uploadImageHandler}
          slug={detailData.slug}
          setSelectedMenu={setSelectedMenu}
          onClickSubmit={onClickSubmit}
          url={process.env.REACT_APP_DOCUMENTATION_API}
          userRole="user"
          data={formData}
          section="documentations"
        ></UpdateDocumentation>
      );
    }
  };

  return (
    <>
      {msg}
      {loading}
      {renderBody()}
    </>
  );
};

export default DocumentationContainer;
