import React from 'react'
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import ScheduleRoundedIcon from '@material-ui/icons/ScheduleRounded';
import SettingsIcon from '@material-ui/icons/Settings';
import DescriptionIcon from '@material-ui/icons/Description';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import classes from './ItemIcon.module.css';
import RoomIcon from '@material-ui/icons/Room';
import SettingsSystemDaydreamIcon from '@material-ui/icons/SettingsSystemDaydream';
import PermMediaIcon from '@material-ui/icons/PermMedia';

const MainMenuItem = (props) =>{
    if(props.iconName == "dashboard"){
        return (<HomeRoundedIcon className={classes.icon}/>)
    }else if(props.iconName == "user-manager"){
        return (<PeopleAltIcon className={classes.icon}/>)
    }else if(props.iconName == "documentation"){
        return (<DescriptionIcon className={classes.icon}/>)
    }else if(props.iconName == "settings"){
        return (<SettingsIcon className={classes.icon}/>)
    }else if(props.iconName == "project-manager"){
        return (<AccountTreeIcon className={classes.icon}/>)
    }else if(props.iconName == "schedule-manager"){
        return (<ScheduleRoundedIcon className={classes.icon}/>)
    }else if(props.iconName == "media-manager"){
        return (<PermMediaIcon className={classes.icon}/>)
    }else if(props.iconName == "room-manager"){
        return (<RoomIcon className={classes.icon}/>)
    }else if(props.iconName == "patch-manager"){
        return (<SettingsSystemDaydreamIcon className={classes.icon}/>)
    }
    
    return (
        ""
    )
}

export default MainMenuItem;