import React, {useState, useEffect} from 'react';
import MaterialTable from 'material-table';
import useGlobal from '../../../../components/Hooks/useGlobal';
import Axios from 'axios';
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import { forwardRef } from "react";


const SocketManagerContainer = (props) =>{
    const [client, setClient] = useState([]);
    const [globalState, globalActions] = useGlobal();
    
    useEffect(()=>{
      globalState.socket.on("getClient", data =>{         
        setClient(data.client)
      })      
    })
    useEffect(()=>{      
      fetchClient();
    },[])

    const fetchClient = () =>{
      Axios.get(
      `${process.env.REACT_APP_SOCKET}/api/index/${process.env.REACT_APP_KEY}/${globalState.userCookies}`,
        {
          headers: { Authorization: `jwt ${globalState.userCookies}` }
        }
      ).then((response)=>{
        const data = response.data
        setClient(data)
      }).catch((err)=>{
        console.log(err)
      })
    }

    return <>
        <MaterialTable
         title="Connected Client"
         columns={[
           { title: 'Application Name', field: 'appName' },
           { title: 'Connection Start', field: 'created_at' },
         ]}
         options={{
           search: false
         }}
         data={client}   
         icons={{     
          FirstPage: forwardRef((props, ref) => (
            <FirstPage {...props} ref={ref} />
          )),
          LastPage: forwardRef((props, ref) => (
            <LastPage {...props} ref={ref} />
          )),
          NextPage: forwardRef((props, ref) => (
            <ChevronRight {...props} ref={ref} />
          )),
          PreviousPage: forwardRef((props, ref) => (
            <ChevronLeft {...props} ref={ref} />
          )),
        }}          
        />
    </>
}

export default SocketManagerContainer