import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {
    Card,
    CardContent,
    Grid,
    TextField,
    Button,
} from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TitleIcon from "@material-ui/icons/Title";
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import PersonIcon from '@material-ui/icons/Person';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    formControl: {
        minWidth: 140,
    },
    
}));

const PatchForm = (props) => {
    const classes = useStyles();


    const form = () =>{
        return (
            <Card variant="outlined">
                <CardContent>
                    <form method="post" onSubmit={props.onClickSubmit}>
                        <Grid container spacing={1} alignItems="flex-end">
                            <Grid item>
                                <TitleIcon />
                            </Grid>
                            <Grid item md={true} sm={true} xs={true}>
                                <TextField
                                    id="title"
                                    label="Title"
                                    type="text"
                                    fullWidth
                                    value={props.postData.title}
                                    autoFocus
                                    required
                                    onChange={(e) => {
                                        props.dataHandler(
                                            "title",
                                            e.target.value
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>
                       
                       
                        <Grid container spacing={1} alignItems="flex-end">
                            <Grid item>
                            <PersonIcon /> 
                            </Grid>
                            <Grid item md={true} sm={true} xs={true}>
                                {/* <MultipleSelectTags value={props.postData.users} options={props.documentations} label={"Person"} type="users" {...props} />
                                 */}
                                 
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-label">Documentation</InputLabel>
                                    <Select                                  
                                        value={props.postData.documentation}
                                        onChange={(e)=>props.dataHandler('documentation', e.target.value)}
                                    >
                                        {props.documentations.map(doc=>{
                                            return <MenuItem key={doc.slug} value={doc.slug}>{doc.title}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                              
                            </Grid>
                        </Grid>
                                                        
                        <Grid
                            container
                            justify="center"
                            style={{ marginTop: "20px" }}
                        >
                            <Button
                                variant="outlined"
                                color="primary"
                                style={{ textTransform: "none" }}
                                type="submit"
                                fullWidth
                            >
                                {props.isEdit ? 'Edit' : 'Insert'}
                            </Button>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        );
    }
    

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={props.openModal}
                onClose={props.handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 100,
                }}
            >
                <Fade in={props.openModal}>
                    {form()}
                </Fade>
            </Modal>
        </div>
    );
};

export default PatchForm;
