import React, {useState, useEffect} from 'react';
import MenuBar from '../../components/Navigation/MenuBar/MenuBar';
import Message from "../../components/UI/Message/Message";
import useGlobal from '../../components/Hooks/useGlobal';
import Axios from "axios";
import { transformDateWithSeconds } from '../../components/Helper/DateHelper';

const HomeContainer = (props) => {

    const [globalState, globalActions] = useGlobal();
    const [schedules, setSchedules] = useState([]);
    //nantinya ini diturunin ke semua komponen, masih berantakan!
    const [message, setMessage] = useState({
      text: "",
      type: "",
      status: false
    });
    const messageHandler = (text, type, status) => {
      let newMessage = { ...message };
      newMessage.text = text;
      newMessage.type = type;
      newMessage.status = status;
      setMessage(newMessage);
    };
    const user = props.userData;
  
    let msg = message.status ? (
      <Message text={message.text} icon={message.type} handler={messageHandler} />
    ) : (
      ""
    );
    const getSchedules = async () => {
        try {
            let data = await Axios.get(
            `${process.env.REACT_APP_SCHEDULER_PROJECT_API}user/schedule/today`,
            {
                headers: globalState.httpReqHeaders
            }
            );
            let payload = data.data.payload;
            //masukin ke global state untuk alarm
            globalActions.setSchedules(payload);
            setSchedules(payload);
            
        } catch (e) {
            globalActions.setSchedules([]);
        }
    };

    useEffect(()=>{
        getSchedules();
        globalState.socket.on("newSchedule", data =>{
            getSchedules();
        });
        globalState.socket.on("getDateAndTime", data =>{

            console.log(data);
            globalActions.setDate(transformDateWithSeconds(new Date(data)));
        });
    },[]);

    useEffect(() => {
        var timeout = setTimeout(() => {
            if(globalState.schedules.length > 0){
                const difference = +new Date(globalState.schedules[0].ts) - +new Date();
                if(difference <= 0){
			messageHandler("Current schedule: " + globalState.schedules[0].text, 'info', true)
	    		clearTimeout(timeout);
	    		getSchedules();
                }
            }
        }, 1000);
    });

    const menu = {
        Admin: [
            {
                link: '/home/admin/dashboard',
                icon : 'dashboard',
                text : 'Dashboard',
            },
            {
                link: '/home/admin/user-manager',
                icon : 'user-manager',
                text : 'User Manager',
            },
            {
                link: '/home/documentation',
                icon : 'documentation',
                text : 'Documentation',
            },
            {
                link: '/home/admin/patch-manager',
                icon : 'patch-manager',
                text : 'Patch Manager',
            },
            {
                link: '/home/admin/room-manager',
                icon : 'settings',
                text : 'Room Manager',
            },
            {
                link: '/home/admin/schedule-manager',
                icon : 'schedule-manager',
                text : 'Schedule Manager',
            },
            {
                link: '/home/admin/project-manager',
                icon : 'project-manager',
                text : 'Project Manager',
            },
            {
                link: '/home/admin/media-manager',
                icon : 'media-manager',
                text : 'Media Manager',
            },
            {
                link: '/home/admin/socket-manager',
                icon : 'settings',
                text : 'Socket Manager',
            },
        ],
        Staff:[       
            {
                link: '/home',
                icon : 'home',
                text : 'Home',
            }
        ],
    }
    
    let role = user.Role.roleName;
    let selectedMenu = menu[role];
    return (
        <div>
            {msg}
            <MenuBar menu={selectedMenu} userData={user} userCookies={props.userCookies} socket={props.socket} messageHandler={messageHandler}/>
        </div>
    )
}

export default HomeContainer
