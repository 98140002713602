import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import DateFnsUtils from '@date-io/date-fns';
import {
    Card,
    CardContent,
    Grid,
    TextField,
    Divider,
    Typography,
    Button,
} from "@material-ui/core";
import TitleIcon from "@material-ui/icons/Title";
import PersonIcon from '@material-ui/icons/Person';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import ScheduleIcon from "@material-ui/icons/Schedule";
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker
  } from '@material-ui/pickers';
import Tabular from "../../../../../components/UI/Tabular/Tabular";
import MultipleSelectTags from "../../../../../components/UI/MultipleSelectTags/MultipleSelectTags";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

const ScheduleForm = (props) => {
    const classes = useStyles();

    const [time, setTime] = useState();
    const [selectedDate, setSelectedDate] = useState(props.postData.date);
  
    const handleDateChange = (date) => {
      setSelectedDate(date);
      props.dataHandler("date", date);
    };
  

    const form = () =>{
        return (
            <Card variant="outlined">
                <CardContent>
                    <form method="post" onSubmit={props.onClickSubmit}>
                        <Grid container spacing={1} alignItems="flex-end">
                            <Grid item>
                                <TitleIcon />
                            </Grid>
                            <Grid item md={true} sm={true} xs={true}>
                                <TextField
                                    id="title"
                                    label="Title"
                                    type="text"
                                    fullWidth
                                    value={props.postData.title}
                                    autoFocus
                                    required
                                    onChange={(e) => {
                                        props.dataHandler(
                                            "title",
                                            e.target.value
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} alignItems="flex-end">
                            <Grid item>
                                <ScheduleIcon />
                            </Grid>
                            <Grid item md={true} sm={true} xs={true}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDateTimePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="Date picker dialog"
                                        format="MM/dd/yyyy HH:mm"
                                        ampm={false}
                                        minDate={new Date()+1}
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                       
                        <Grid container spacing={1} alignItems="flex-end">
                            <Grid item>
                            <PersonIcon /> 
                            </Grid>
                            <Grid item md={true} sm={true} xs={true}>
                                <MultipleSelectTags value={props.postData.users} options={props.people} label={"Person"} type="users" {...props} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} alignItems="flex-end">
                            <Grid item>
                            <PeopleAltIcon /> 
                            </Grid>
                            <Grid item md={true} sm={true} xs={true}>
                                <MultipleSelectTags value={props.postData.groups} options={props.group} label={"Group"} type="groups" {...props} />
                            </Grid>
                        </Grid>
                                                        
                        <Grid
                            container
                            justify="center"
                            style={{ marginTop: "20px" }}
                        >
                            <Button
                                variant="outlined"
                                color="primary"
                                style={{ textTransform: "none" }}
                                type="submit"
                                fullWidth
                            >
                                {props.isEdit ? 'Edit' : 'Insert'}
                            </Button>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        );
    }
    

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={props.openModal}
                onClose={props.handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 100,
                }}
            >
                <Fade in={props.openModal}>
                    {form()}
                </Fade>
            </Modal>
        </div>
    );
};

export default ScheduleForm;
