import React, { useState, useEffect } from "react";
import DataTable from "../../../../../components/UI/DataTable/DataTable";
import ComboBox from "../../../../../components/UI/ComboBox/ComboBox";

const RoleManager = props => {
  const [specialData, setSpecialData] = useState();

  const [columns, setColumns] = useState([
    { title: "Role Name", field: "roleName" },
    {
      title: "Organization",
      field: "Organization.organizationName",
      editComponent: prop => (
        <ComboBox
          section="organization"
          userRole="admin"
          userCookies={props.userCookies}
          label="organizationName"
          value={prop.value}
          title="Organization"
          setSpecialData={specialDataHandler}
          {...props}
        />
      )
    }
  ]);

  const specialDataHandler = param => {
    setSpecialData({
      organizationId: param
    });
  };

  return (
    <>
      <DataTable
        {...props}
        section="role"
        userRole="admin"
        columns={columns}
        insertable={true}
        title="Role Manager"
        editable={true}
        specialData={specialData}
      ></DataTable>
    </>
  );
};

export default RoleManager;
