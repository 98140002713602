import React, { Component, useState, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import classes from './App.module.css';
import LoginContainer from './containers/Login/LoginContainer';
import HomeContainer from './containers/Home/HomeContainer';
import PrivateRoute from './components/PrivateRoute';

const App = () =>{


  return(
    <div className={classes.Prook_App}>
      <Switch>
        <Route path="/" exact component={LoginContainer}/>
        <PrivateRoute path="/home" component={HomeContainer}/>
        <Redirect to="/"/>
      </Switch>
    </div>
  )
}
export default App;
