import React, { useState, useEffect } from "react";
import "../../../../../node_modules/react-calendar/dist/Calendar.css";
import { Paper, Button, Divider, Grid } from "@material-ui/core";
import "../../../../../node_modules/react-calendar/dist/Calendar.css";
import classes from "./ScheduleList.module.css";
import Axios from 'axios';
import Loading from "../../../../components/UI/Loading/Loading";
import Table from "../../../../components/UI/Table/Table";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';


const ScheduleHistory = (props) => {

    const [events, setEvents] = useState([]);
    const [isLoad, setIsLoad] = useState(false);
    
    useEffect(() => {
        if(events.length === 0){
            fetchSchedule();
        }
    }, [events]);

    const fetchSchedule = async () => {
        setIsLoad(true);
        try {
            let data = await Axios.get(
            `${process.env.REACT_APP_SCHEDULER_PROJECT_API}user/schedule/all`,
            {
                headers: { Authorization: `jwt ${props.userCookies}` }
            }
            );
            let payload = data.data.payload;
            payload.map((data)=>{
                if(data.deleted){
                    data.text = data.text + ' - CANCELED'
                }
            })
            setEvents(payload);
        } catch (e) {
            props.messageHandler("Failed to load data", "error", true);
            setEvents([]);
        }
        setIsLoad(false);
    };



    return (
        <>
            {isLoad ? <Loading /> : ""}
            <Paper elevation={0} className={classes.Paper}>                
                <Table
                columns={props.columns}
                data={events}        
                detailPanel={rowData => {
                    return (
                    <div className={classes.DetailPanel}>
                        <h1 className={classes.Title}>{rowData.text}</h1>
                        <h5 className={classes.DateColor}>
                        {
                            props.transformDate(new Date(rowData.ts))
                        }
                        </h5>
                        <Divider/>
                        <br/>
                        <h3>Participants:</h3>
                        
                        <List dense>
                        {rowData.users.map(user =>{
                            return (
                            <ListItem key={user.id} button>            
                                <ListItemAvatar>
                                <Avatar
                                    alt={`Avatar `}
                                    src={`/static/`}
                                />
                                </ListItemAvatar>
                                <ListItemText id={user.id} primary={user.username} />
                            </ListItem>)
                        })}
                        </List>
                        <List dense>
                        {rowData.groups.map(group =>{
                            return (
                            <ListItem key={group.id} button>       
                            <ListItemAvatar>
                                <Avatar
                                alt={`Avatar `}
                                src={`/static/`}
                                />
                            </ListItemAvatar>                              
                                <ListItemText id={group.id} primary={'Group - ' + group.organizationName} />
                            </ListItem>)
                        })}
                        </List>
                    </div>
                        )
                    }
                    }
                title="All Schedule"
                />
            </Paper>
        </>
    );
};

export default ScheduleHistory;
