import React, { useEffect, useState , useRef} from "react";
import {
  Card,
  CardContent,
  Button,
  Typography,
  Divider
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import axios from "axios";
import { useHistory } from "react-router-dom";
import DataTable from "../../../../components/UI/DataTable/DataTable";
import ComboBox from "../../../../components/UI/ComboBox/ComboBox";
import useGlobal from "../../../../components/Hooks/useGlobal";

const PatchDetails = props => {
    const [data, setData] = useState(null);
    const [isLoad, setIsLoad] = useState(false);
    const history = useHistory();
    const [specialData, setSpecialData] = useState();
    const [globalState, globalActions] = useGlobal();
    const [columns, setColumns] = useState([
        {
            title: "Room",
            field: "room_code",
            editComponent: prop => (
              <ComboBox
                section="room"
                userRole="user"
                label="room_code"
                value={prop.value}
                url={process.env.REACT_APP_DEFAULT_API}
                title="Room code"
                setSpecialData={specialDataHandler}
                {...props}
              />
            )
          },
        { 
            title: "status", field: "status", editable: 'onUpdate',
            lookup: {'pending':'pending', 'running':'running', 'patched':'patched'},
        }
        
    ])

    const specialDataHandler = param => {
        setSpecialData({
            room_id: param
        });
    };

    useEffect(() => {
        if (data === null) {
            getPatchDetails();
        } 
    }, []);

    const getPatchDetails = async () => {
        setIsLoad(true);
        try {
        let newData = await axios.get(
            `${process.env.REACT_APP_DOCUMENTATION_API}user/patch/get/${props.match.params.slug}`,
            {
                headers: globalState.httpReqHeaders
            }
        );
        setData(newData.data.payload);
        } catch (e) {
            props.messageHandler("Failed to get patch details", "error", true);
            setData([]);
        }
        setIsLoad(false);
    };

    const insertHandler = async(data)=>{
        setIsLoad(true);
        try {
        let newData = await axios.post(
            `${process.env.REACT_APP_DOCUMENTATION_API}user/patch/${props.match.params.slug}`,
            specialData,
            {
                headers: globalState.httpReqHeaders
            }
        );
        setData(newData.data.payload);
        } catch (e) {
            props.messageHandler("Failed to insert room", "error", true);
        }finally {
            getPatchDetails();
        }
        setIsLoad(false);

    }

    const updateHandler = async(data)=>{
        setIsLoad(true);

        try {
        let newData = await axios.patch(
            `${process.env.REACT_APP_DOCUMENTATION_API}user/patch/${props.match.params.slug}`,
            data,
            {
                headers: globalState.httpReqHeaders
            }
        );
        setData(newData.data.payload);
        } catch (e) {
            props.messageHandler("Failed to update room", "error", true);
            
        } finally {
            getPatchDetails();
        }
        setIsLoad(false);

    }

    const deleteHandler = async(data)=>{
        setIsLoad(true);

        try {
        let newData = await axios.delete(
            `${process.env.REACT_APP_DOCUMENTATION_API}user/patch/${props.match.params.slug}/${data._id}`,
    
            {
                headers: globalState.httpReqHeaders
            }
        );
        setData(newData.data.payload);
        } catch (e) {
            props.messageHandler("Failed to delete room", "error", true);
            
        } finally {
            getPatchDetails();
        }
        setIsLoad(false);

    }

    const detailHandler = roomSlug=>{
        history.push(`/home/admin/patch-manager/${props.match.params.slug}/${roomSlug}`)
    }

    return (
        <>
        {/* {isLoad ? <Loading /> : ""} */}
        <Card variant="outlined">
            <CardContent>
                
            <Typography color="textPrimary" variant='h5'> 
                {data != null && data.title}
            </Typography>
            <Typography color="textPrimary" 
            style={data!=null && data.status ? {color:'#4caf50'} : {color:'#b23c17'}}            
            gutterBottom> 
                {data!=null && (data.status ? "Patched" : "On progress")}            
            </Typography>
                <DataTable
                messageHandler={props.messageHandler}
                columns={columns}
                isLoad={isLoad}
                detail={false}
                data={data && data.rooms || []}      
                insertable={true}   
                insertHandler={insertHandler}
                detailAction={true}
                editable={true}
                detailHandler={detailHandler}   
                updateHandler={updateHandler}    
                deleteHandler={deleteHandler}
                title="Rooms to be patched"
                />
            </CardContent>
            <Button
            size="medium"
            style={{ marginTop: "30px" }}
            color="secondary"
            onClick={e => history.push(`/home/admin/patch-manager`)}
            >
            <ArrowBackIcon />
            Back
            </Button>
        </Card>
        </>
    );
};

export default PatchDetails;
