import {
    useState,
    useEffect,
    useRef
} from 'react';
import {
    useCookies
} from 'react-cookie';
import Axios from 'axios';
import useGlobal from '../components/Hooks/useGlobal';
import { socket } from '../components/Helper/SocketHelper';

export const useUserData = () => {
    // eslint-disable-next-line
    const [cookies, setCookie, removeCookie] = useCookies(['_u']);
    const [userData, setUserData] = useState(null);

    const [globalState, globalActions] = useGlobal();
    useEffect(() => {
        Axios.defaults.withCredentials = false;
        let isSubscribed = true;
        
        const getUserData = async () => {
            let data;
            if (cookies._u === "undefined" || cookies._u === undefined) {
                data = false;
            } else {
                try {
                    data = await Axios.get(process.env.REACT_APP_DEFAULT_API + 'user', {
                        headers:{Authorization: `jwt ${cookies._u}`}
                    });
                    const Socket = socket(process.env.REACT_APP_KEY, cookies._u)
                    globalActions.addHTTPReqHeaders(cookies._u);
                    globalActions.addUserCookies(cookies._u);
                    globalActions.addSocket(Socket);
                    if (data.data.status && Object.keys(data.data.payload).length > 0) {
                        data = data.data.payload;
                    } else {
                        // clear invalid cookie
                        removeCookie('_u');
                        data = null;
                    }
                } catch (e) {
                    // clear invalid cookie
                    removeCookie('_u');
                    data = null;
                }

            }

            if (isSubscribed) {
                setUserData(data);
            }
        }

        getUserData();

        return () => isSubscribed = false
    }, []);

    return userData;
};

export const usePrevious = (value) => {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}
export const useUserCookies = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['_u']);
    let [userCookie, setUserCookie] = useState();

    useEffect(()=>{
        setUserCookie(cookies._u);
    })
    return userCookie;
}
