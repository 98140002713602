import React, { useState, useEffect } from "react";
import Message from "../../../components/UI/Message/Message";
import useGlobal from "../../../components/Hooks/useGlobal";
import { Paper, Button, Divider, Grid } from "@material-ui/core";
import classes from "./ProjectManager.module.css";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import Axios from 'axios';
import Loading from "../../../components/UI/Loading/Loading";
import ProjectForm from './ProjectForm/ProjectForm'
import ProjectList from "./ProjectList/ProjectList";
import { TablePagination } from '@material-ui/core';
import { transformDate } from '../../../components/Helper/DateHelper';

const ProjectManager = (props) =>{
    
    const [pageNumber, setPageNumber] = useState(0); // start from 0, 0 = first page
    const [dataCount, setDataCount] = useState(0);
    const [projects, setProjects] = useState([]);
    const [globalState, globalActions] = useGlobal();
    const [openModal, setOpenModal] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const [message, setMessage] = useState({
        text: "",
        type: "",
        status: false
    });
    const [people, setPeople] = useState([]);
    const [group, setGroup] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [postData, setPostData] = useState({
      id:'',
      title : '',
      description: '',
      date : new Date(),
      users: [],
      groups: [],
      status: '',
    });
    useEffect(() => {
      if(projects.length === 0){
          fetchProject(pageNumber);
      }
      if (people.length === 0) {
        fetchPeopleData();
      }
      if(group.length === 0){
        fetchGroupData();
      }
    }, []);

    const components = {
      Pagination: props => (
        <TablePagination
          {...props}
          rowsPerPageOptions={[]}
          rowsPerPage={5}
          count={dataCount}
          page={pageNumber}
          onChangePage={(e, page) =>
            {
              setPageNumber(page)
              fetchProject(page)
            }}
         
        />
      ),

    }
    const columns = [
      { 
        title: 'Deadline', 
        field: 'schedule.ts',
        type: 'datetime',
        render: rowData =>{
          if(rowData.schedule === null || rowData.schedule.ts === null){
            return '-'
          }
          return(
            transformDate(new Date(rowData.schedule.ts))
          )
          
        },
        cellStyle: {
          width: '30%',
        },
        headerStyle: {
          width: '30%',
        }
      },
      { 
        title: 'Project Name',
        field: 'schedule.text',
      },
      { 
        title: 'Project Key',
        field: 'key',
      },
      { 
        title: 'Project Status',
        field: 'status'
      },
    ]

  const postDataHandler = (type, data) =>{
    const prevData = {...postData};
    if(type === 'title'){
      prevData.title = data;
    }else if(type === 'date'){
      prevData.date = data;
    }else if(type === 'users'){
      prevData.users = data;
    }else if(type === 'groups'){
      prevData.groups = data;
    }else if(type === 'description'){
      prevData.description = data;
    }
    console.log(data)
    setPostData(prevData);
  }

  const submitHandler = (evt) =>{
    if(!isEdit){
      postHandler(evt)
    }else{
      updateProject(evt)
    }
  }
  const updateProject = async(evt) =>{
    evt.preventDefault();
    setIsLoad(true);
    try {
      Axios.patch(
        `${process.env.REACT_APP_SCHEDULER_PROJECT_API}user/project/update`,
        postData,
        {
          headers: globalState.httpReqHeaders
        }
      ).then((res)=>{
        messageHandler("Project updated!", "success", true);
        fetchProject(pageNumber);
        handleClose();
      }).catch(error => {
        if (!error.response) {
        console.log(error)
          messageHandler(
            "Unexpected error, please contact the administrator",
            "error",
            true
          );
        } else {
          messageHandler(
            "Error (" +
              error.response.status +
              "): " +
              error.response.data.message,
            "error",
            true
          );
      }
      })
    
    } catch (error) {
      messageHandler(
        "Unexpected error, please contact the administrator",
        "error",
        true
      );
    } finally{
      setIsLoad(false);
    }
  }

  const postHandler = async(evt) =>{
    evt.preventDefault();
    setIsLoad(true);
    try {
      Axios.post(
        `${process.env.REACT_APP_SCHEDULER_PROJECT_API}user/project/add`,
        postData,
        {
          headers: globalState.httpReqHeaders
        }
      ).then((res)=>{
        messageHandler("New Project Added!", "success", true);
        fetchProject(pageNumber);
        handleClose();
      }).catch(error => {
        if (!error.response) {
        console.log(error)
          messageHandler(
            "Unexpected error, please contact the administrator",
            "error",
            true
          );
        } else {
          messageHandler(
            "Error (" +
              error.response.status +
              "): Unexpected error, please contact the administrator",
            "error",
            true
          );
      }
      })
    
    } catch (error) {
      messageHandler(
        "Unexpected error, please contact the administrator",
        "error",
        true
      );
    } finally{
      setIsLoad(false);
    }
  }

  const cancelProject = async(data) =>{    
    setIsLoad(true);
    Axios.delete(
      `${process.env.REACT_APP_SCHEDULER_PROJECT_API}user/project/delete`,
      {
        headers: globalState.httpReqHeaders,
        data: data
      })
      .then(() => {
        messageHandler("Project has been canceled", "success", true);
        fetchProject(pageNumber);
        setIsLoad(false);
      })
      .catch(error => {
        messageHandler(
          "Error (" +
            error.response.status +
            "): " +
            "Unexpected error, please contact the administrator",
          "error",
          true
        );
        setIsLoad(false);
      });
  }

  const fetchPeopleData = async () => {
    setIsLoad(true);
    try {
      let data = await Axios.get(
        `${process.env.REACT_APP_DEFAULT_API}${props.userData.Role.roleName}/user/all`,
        {
          headers: globalState.httpReqHeaders
        }
      );
      let payload = data.data.payload;
      payload.map((item, index)=>{
        item.title = item.username
      })
      setPeople(payload);
    } catch (e) {
      messageHandler("Failed to load data", "error", true);
      setPeople([]);
    }
    setIsLoad(false);
  };

  
  const fetchGroupData = async () => {
    setIsLoad(true);
    try {
      let data = await Axios.get(
        `${process.env.REACT_APP_DEFAULT_API}${props.userData.Role.roleName}/organization/all`,
        {
          headers: globalState.httpReqHeaders
        }
      );
      let payload = data.data.payload;
      payload.map((item, index)=>{
        item.title = item.organizationName
      })
      setGroup(payload);
    } catch (e) {
      messageHandler("Failed to load data", "error", true);
      setGroup([]);
    }
    setIsLoad(false);
  };  


  const fetchProject = async (page) => {
      setIsLoad(true);
      try {
          let data = await Axios.get(
          `${process.env.REACT_APP_SCHEDULER_PROJECT_API}user/project/all/${page}`,
          {
            headers: globalState.httpReqHeaders
          }
          );
          let payload = data.data.payload;
          setProjects(payload);
          setDataCount(data.data.dataCount);
      } catch (e) {
          messageHandler("Failed to load data", "error", true);
          setProjects([]);
      }
      setIsLoad(false);
  };
  
    const handleUpdate = (rowData) =>{      
        const newPostData = postData;
        newPostData.id = rowData._id;
        newPostData.title = rowData.schedule.text;
        newPostData.date = rowData.schedule.ts;
        newPostData.users = rowData.schedule.users;
        newPostData.groups = rowData.schedule.groups;
        newPostData.description = rowData.description;
        setPostData(newPostData);
        setIsEdit(true);
        setOpenModal(true);
    }

    const handleInsert = () =>{      
        const newPostData = postData;
        newPostData.title = '';
        newPostData.date = new Date();
        newPostData.users = [];
        newPostData.groups = [];
        setPostData(newPostData);
        setIsEdit(false);
        setOpenModal(true);
    }

    const handleClose = () => {
        setOpenModal(false);
    };
    const renderForm = () => {
        if (openModal) {
            return <ProjectForm {...props}
            postData={postData}
            messageHandler={messageHandler}
            people={people}
            dataHandler={postDataHandler}
            onClickSubmit={submitHandler}
            group={group}        
            isEdit={isEdit}
            handleClose={handleClose} 
            openModal={openModal} 
            time={postData.time}/>;
        } 
    
    };
    
    
    const messageHandler = (text, type, state) => {
        let newMessage = { ...message };
        newMessage.text = text;
        newMessage.type = type;
        newMessage.status = state;
        setMessage(newMessage);
    };

    let msg = message.status ? (
        <Message text={message.text} icon={message.type} handler={messageHandler} />
    ) : (
        ""
    );

    return (
        <>
          {msg}
          <Paper elevation={0} className={classes.Paper}>

              {renderForm()}
              <ProjectList 
                {...props}
                isLoad={isLoad}
                components={components}
                columns={columns}
                transformDate={transformDate}
                globalState={globalState}
                messageHandler={messageHandler}
                projects={projects}
                cancelProject={cancelProject}
                handleUpdate={handleUpdate}
                isEdit={isEdit}
              />
              <Button
                  variant="outlined"
                  size="medium"
                  color="primary"
                  onClick={handleInsert}
              >
                  <AddCircleOutlineOutlinedIcon />
                  &nbsp;Add
              </Button>
          </Paper>
        </>
    );
}

export default ProjectManager;