import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import Axios from "axios";
import useGlobal from "../../../components/Hooks/useGlobal";

const ComboBox = props => {
  const [globalState, globalActions] = useGlobal();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await Axios.get(
        `${props.url}${props.userRole}/${props.section}/all`,
        {
          headers: globalState.httpReqHeaders
        }
      );
      const data = response.data.payload;
      if (active) {
        setOptions(data);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);
  return (
    <Autocomplete
      style={{ width: 200 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={option => option[props.label]}
      options={options.sort(
        (a, b) => -b[props.label].localeCompare(a[props.label])
      )}
      loading={loading}
      onChange={(e, newValue) =>
        newValue !== null ? props.setSpecialData(newValue.id) : ""
      }
      disableClearable
      renderInput={params => (
        <TextField
          {...params}
          label={props.title}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
    />
  );
};

export default ComboBox;
