import React, { useState, useEffect } from "react";
import DataTable from "../../../../../components/UI/DataTable/DataTable";

const AccountManager = props => {
  const [columns, setColumns] = useState([
    { title: "Username", field: "username" },
    { title: "Organization", field: "Organization.organizationName" },
    { title: "Role", field: "Role.roleName" },
  ]);

  return (
    <>
      <DataTable
        {...props}
        section="user"
        userRole="admin"
        title="Account Manager"
        columns={columns}
      ></DataTable>
    </>
  );
};

export default AccountManager;
