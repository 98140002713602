import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import SaveAlt from "@material-ui/icons/SaveAlt";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Add from "@material-ui/icons/Add";
import Check from "@material-ui/icons/Check";
import FilterList from "@material-ui/icons/FilterList";
import Remove from "@material-ui/icons/Remove";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import ClearIcon from "@material-ui/icons/Clear";
import Axios from "axios";
import Loading from "../Loading/Loading";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { forwardRef } from "react";
import useGlobal from "../../Hooks/useGlobal";

const DataTable = props => {
  Axios.defaults.withCredentials = true;
  const [data, setData] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const [globalState, globalActions] = useGlobal();

  const fetchData = async () => {
    setIsLoad(true);
    try {
      let data = await Axios.get(
        `${props.url}${props.userRole}/${props.section}/all`,
        {
          headers: globalState.httpReqHeaders
        }
      );
      setData(data.data.payload);
      setIsLoad(false);
    } catch (e) {
      setIsLoad(false);
      props.messageHandler("Failed to load data", "error", true);
      setData([]);
    }
  };
  useEffect(() => {
    if (data.length === 0 && !props.data) {
      fetchData();
    }
  }, []);

  const getRequestBody = inputData => {
    let content = {};
    content.id = inputData.id;
    props.columns.map((column, i) => {
      content[column.field] = inputData[column.field];
    });
    return { ...content, ...props.specialData };
  };

  const addData = newData =>
    new Promise(resolve => {
      resolve();
      Axios.post(
        `${props.url}${props.userRole}/${props.section}/add`,
        getRequestBody(newData),
        {
          headers: globalState.httpReqHeaders
        }
      )
        .then(() => {
          props.messageHandler(`New ${props.section} added`, "success", true);
          fetchData();
        })
        .catch(error => {
          if (!error.response) {
            props.messageHandler(
              "Unexpected error, please contact the administrator",
              "error",
              true
            );
          } else {
            props.messageHandler(
              "Error (" +
                error.response.status +
                "): " +
                error.response.data.message,
              "error",
              true
            );
          }
        });
      return;
    });

  const removeData = oldData =>
    new Promise(resolve => {
      resolve();

      Axios.delete(`${props.url}${props.userRole}/${props.section}`, {
        headers: globalState.httpReqHeaders,
        data: getRequestBody(oldData)
      })
        .then(() => {
          props.messageHandler("Delete Success", "success", true);
          fetchData();
        })
        .catch(error => {
          if (!error.response) {
            props.messageHandler(
              "Unexpected error, please contact the administrator",
              "error",
              true
            );
          } else {
            props.messageHandler(
              "Error (" +
                error.response.status +
                "): " +
                error.response.data.message,
              "error",
              true
            );
          }
        });
      return;
    });

  const updateData = newData =>
    new Promise(resolve => {
      resolve();
      Axios.patch(
        `${props.url}${props.userRole}/${props.section}`,
        getRequestBody(newData),
        {
          headers: globalState.httpReqHeaders
        }
      )
        .then(() => {
          props.messageHandler("Update Success", "success", true);
          fetchData();
        })
        .catch(error => {
          if (!error.response) {
            props.messageHandler(
              "Unexpected error, please contact the administrator",
              "error",
              true
            );
          } else {
            props.messageHandler(
              "Error (" +
                error.response.status +
                "): " +
                error.response.data.message,
              "error",
              true
            );
          }
        });
      return;
    });

  return (
    <>
      {isLoad ? <Loading /> : ""}
      <MaterialTable
        title={props.title}
        columns={props.columns}
        data={props.data || data}
        isLoading={props.isLoad || isLoad}
        detailPanel={props.detailPanel}
        components={props.components}
        onRowClick={props.detail ? ((event, rowData, togglePanel) => togglePanel()) : ''} 
        icons={{
          Add: forwardRef((props, ref) => <Add {...props} ref={ref} />),
          Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
          ResetSearch: forwardRef((props, ref) => (
            <ClearIcon {...props} ref={ref} />
          )),
          Clear: forwardRef((props, ref) => <ClearIcon {...props} ref={ref} />),
          Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
          Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
          Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
          Filter: forwardRef((props, ref) => (
            <FilterList {...props} ref={ref} />
          )),
          FirstPage: forwardRef((props, ref) => (
            <FirstPage {...props} ref={ref} />
          )),
          LastPage: forwardRef((props, ref) => (
            <LastPage {...props} ref={ref} />
          )),
          NextPage: forwardRef((props, ref) => (
            <ChevronRight {...props} ref={ref} />
          )),
          PreviousPage: forwardRef((props, ref) => (
            <ChevronLeft {...props} ref={ref} />
          )),
          Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
          ThirdStateCheck: forwardRef((props, ref) => (
            <Remove {...props} ref={ref} />
          )),
          ViewColumn: forwardRef((props, ref) => (
            <ViewColumn {...props} ref={ref} />
          )),
          DetailPanel: forwardRef((props, ref) => (
            <ChevronRight {...props} ref={ref} />
          )),
          SortArrow: forwardRef((props, ref) => (
            <KeyboardArrowUpIcon {...props} ref={ref} />
          ))
        }}
        options={{
          actionsColumnIndex: -1,
          exportButton: props.export,
          search: props.search,
          filtering: props.filtering,
        }}
        editable={{
          onRowAdd: props.insertable && (newData => {
            console.log(newData)
            if(!props.insertHandler)
              return addData(newData)
            return props.insertHandler(newData)
          }),
          onRowUpdate: props.editable && (newData => {
            if(!props.updateHandler)
              return updateData(newData)
            return props.updateHandler(newData)
          }),
          onRowDelete: oldData => {
            if(!props.deleteHandler)
              return removeData(oldData)
            return props.deleteHandler(oldData)
          }
        }}
        actions={[
          props.insertFreeAction
            ? {
                icon: () => <Add />,
                tooltip: "Add",
                isFreeAction: true,
                onClick: event => props.setSelectedMenu(3)
              }
            : "",
          props.detailAction
            ? {
                icon: () => <ArrowForwardIosIcon />,
                tooltip: "View Details",
                onClick: (event, rowData) => {
                  console.log(rowData)
                  props.isSlug ? props.detailHandler(rowData.slug):props.detailHandler(rowData.id);
                }
              }
            : "",
          rowData =>
            props.updateFreeAction && props.userData.id == rowData.userId
              ? {
                  icon: () => <Edit />,
                  tooltip: "Edit",
                  isFreeAction: true,
                  onClick: (event, rowData) => {
                    props.isSlug ? props.slugHandler(rowData.slug):props.detailHandler(rowData.id);
                    props.setSelectedMenu(4)
                  }
                }
              : ""
        ]}
      />
    </>
  );
};

export default DataTable;
