import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { withCookies, useCookies } from "react-cookie";
import LoginForm from './LoginForm/LoginForm';
import classes from './LoginContainer.module.scss';
import Message from '../../components/UI/Message/Message';
import Loading from '../../components/UI/Loading/Loading';

const LoginContainer = (props) =>{
    const [formData, setFormData] = useState({
        userName: '',
        password: '',
        remember: false,
        isSubmit:false,
    });

    const [message, setMessage] = useState({
        text:'',
        type:'',
        status: false
    });
    const [cookies, setCookie, removeCookie] = useCookies(['_u']);

    useEffect(() =>{
        if ( Object.entries(cookies).length !== 0  && cookies.hasOwnProperty('_u')) {
            props.history.push('/home');
        }
    });

    const messageHandler = (text, type, state) => {
        let newMessage = {...message};
        newMessage.text = text;
        newMessage.type = type;
        newMessage.status = state;
        setMessage(newMessage);
    }


    let msg = (message.status ? (
        <Message 
            text={message.text}
            icon={message.type}
            handler={messageHandler}                                         
        />
    ) : "");

    let loading = (formData.isSubmit ? (
        <Loading/>     
    ) : "");

    const validateUsernameAndPassword = (username, password) => {
        if (username.length === 0) {
            return false;
        } else if (password.length === 0) {
            return false;
        }

        return true;
    }

    const changeIsSubmit = (status) => {
        let prevData = {...formData};
        prevData.isSubmit = status;
        setFormData(prevData);
    }

    const setFormDataHandler = (type, data) => {        
        let newState = {...formData};
        if (type === 'username') {
            newState.userName = data;           
        } else if (type === 'password') {
            newState.password = data;           
        } else if (type === 'remember') {
            newState.remember = !newState.remember;    
        }
        
        setFormData(newState);
    }


    const onClickSubmit = async (evt) => {
        evt.preventDefault();

        changeIsSubmit(true);
        
        if ( !validateUsernameAndPassword(formData.userName, formData.password) ) {
            messageHandler("Invalid username and password!", "error", true);
            changeIsSubmit(false);
            
            return;
        }

        let userName = formData.userName
        // lcas backend loginannya gapake base64
        // let payload = ""      
        // var b = new Buffer(formData.password);
        // payload = b.toString('base64')
        let loginResult;

        try {
            loginResult = await Axios.post(process.env.REACT_APP_DEFAULT_API + 'login', {
                username: userName,
                password: formData.password,
            });
        } catch (error) {
            if(!error.response){
                messageHandler("Unexpected error, please contact the administrator", "error", true);
            } else {
                messageHandler("Error (" + error.response.status + "): " + error.response.data.message, "error", true);
            }

            changeIsSubmit(false);
            return;
        }
        
        if (loginResult.data) {
            if(!loginResult.data.success){
                messageHandler("Error (" + loginResult.data.status + "): " + loginResult.data.message, "error", true);
                changeIsSubmit(false);
                return;
            }
            let today = new Date();
            let token = loginResult.data.token;
            let expiredDate = new Date();
            expiredDate.setDate(today.getDate()+1);
            if(formData.remember){
                expiredDate.setMonth(today.getMonth()+1);
            }
            
            // messageHandler("Login Success", "success", true);
            setCookie('_u', token,{
                expires:expiredDate,
                path: '/',
            });
            // setTimeout(() => {
            //     this.props.history.push("/home");
            // }, 3000);
        }

        changeIsSubmit(false);
    }
    return(
        <div className={classes.Login + " " + classes.Background}>
            {loading}
            {msg}
            <LoginForm
            form={formData}
            message={message}
            setFormDataHandler={setFormDataHandler}
            onClickSubmit={onClickSubmit}
            history={props.history} />
        </div>
    );
}

export default LoginContainer;
