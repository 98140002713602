import React,{ useState, useEffect } from 'react';
import Axios from 'axios';
import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import classes from './TextEditor.module.css'
Quill.register('modules/ImageResize', ImageResize);

class TextEditor extends React.Component {
  
    constructor(props) {
        super(props);
        this.state = { editorContent: '', quill:null};
        this.handleChange = this.handleChange.bind(this);
        this.apiPostImage  = this.apiPostImage.bind(this);
        this.imageHandler = this.imageHandler.bind(this);
        const html = props.html;
        this.quill = null;
        if (html) {
          this.state = {
            editorContent : html,
            quill:null
          };
        }
    }

    handleChange(content, delta, source, editor) {
      this.setState({ editorContent: editor.getContents() });
      console.log(editor.getContents())
      this.props.setContent(JSON.stringify(editor.getContents()));
    }

  
    async apiPostImage(formData) {
      try {
        const data = formData;
        const image = await Axios.post(
          process.env.REACT_APP_MEDIA_API + "media/add",
          data,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization : process.env.REACT_APP_KEY,
            }
          }
        );
        return new Promise((resolve, reject) => {
          resolve({
            link: image.data.url            
          });
        });
      } catch (error) {
        if (!error.response) {
          this.props.messageHandler(
            "Unexpected error, please contact the administrator",
            "error",
            true
          );
        } else {
          this.props.messageHandler(
            "Error (" +
              error.response.status +
              "): " +
              error.response.data.message,
            "error",
            true
          );
        }
      }
    }

    imageHandler() {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();
        input.onchange = async () => {
            const file = input.files[0];
            const formData = new FormData();
            formData.append('media', file);
            let editor = this.quill.getEditor();
            const unprivilegedEditor = this.quill.makeUnprivilegedEditor(editor); //fungsi get selection cuma ada di unprivileged editor
           
            editor.focus(); //editor harus fokus buat setSelection
            const range = unprivilegedEditor.getSelection(true);
            editor.setSelection(range.index + 1);
            const res = await this.apiPostImage(formData);
            editor.deleteText(range.index, 1);
            editor.insertEmbed(range.index, 'image', res.link[0]);
        };
    }

    render() {
        return (
            <div className={classes.TextEditor}>
                <ReactQuill
                    ref={el =>                    
                        this.quill = el
                    }
                    onChange={this.handleChange}
                    placeholder={this.props.placeholder}
                    defaultValue ={this.state.editorContent}
                    modules={{
                      ImageResize: {
				parchment: Quill.import('parchment')
			},
                        toolbar: {
                            container: [
                                [{ header: '1' }, { header: '2' }, { header: [3, 4, 5, 6] }, { font: [] }],
                                [{ size: [] }],
                                [{ 'direction': 'rtl' }],
                                [{ 'color': [] }, { 'background': [] }],
                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                [{ 'script': 'sub'}, { 'script': 'super' }],
                                [{ 'indent': '-1'}, { 'indent': '+1' }], 
                                [{ list: 'ordered' }, { list: 'bullet' }],
                                ['link', 'image', 'video'],
                                [{ 'align': [] }],
                                ['clean'],
                                ['code-block']
                            ],
                            handlers: {
                                image: this.imageHandler
                            }
                        }
                    }}
                />
            </div>
        );
    }
}

export default TextEditor;
