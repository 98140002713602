import React from 'react';
import { Route, Redirect } from "react-router-dom";
import { useUserData, useUserCookies } from '../../hooks/CustomHooks';

const PrivateRoute = ({ component: Component, ...rest}) => {
    const userData = useUserData(); 
    const userCookies = useUserCookies();

    const authRender = (props) => {
        //kalo null dia lagi nunggu ngambil data, false kalo dia gk dapet data
        if (userData === null || userData === "undefined") {
            return "";
        } else if (!userData) {
            return <Redirect to={{ pathname: "/", state: {from: props.location} }}/>
        } else if (userData.username !== undefined && userData.username !== '') {
            
            return <Component {...props} userData={userData} userCookies={userCookies}/>
        }
    };

    return (
        <Route 
            {...rest}
            render={ props => (authRender(props)) }
        />
    );
}

export default PrivateRoute;