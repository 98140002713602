import React, {useState, useEffect} from 'react'
import DataTable from '../../../components/UI/DataTable/DataTable';
import Message from "../../../components/UI/Message/Message";
import ComboBox from "../../../components/UI/ComboBox/ComboBox"
const RoomManagerContainer = (props) =>{
    const [specialData, setSpecialData] = useState();
    const [message, setMessage] = useState({
        text: "",
        type: "",
        status: false
      });
    
      const tabMenu = ["User", "Role", "Organization"];
    
      const messageHandler = (text, type, state) => {
        let newMessage = { ...message };
        newMessage.text = text;
        newMessage.type = type;
        newMessage.status = state;
        setMessage(newMessage);
      };
    
      let msg = message.status ? (
        <Message text={message.text} icon={message.type} handler={messageHandler} />
      ) : (
        ""
      );

    const [columns, setColumns] = useState([
      { title: "Room Code", field: "room_code" },
      { title: "Room Name", field: "room_description" },
      { title: "Capacity", field: "room_capacity" },
      { title: "Room IP", field: "room_ip" },
      {
        title: "Room type",
        field: "type.name",
        editComponent: prop => (
          <ComboBox
            section="room-type"
            userRole="user"
            label="name"
            value={prop.value}
            url={process.env.REACT_APP_DEFAULT_API}
            title="Room type"
            setSpecialData={specialDataHandler}
            {...props}
          />
        )
      },
    ]);
  
    const specialDataHandler = param => {
      setSpecialData({
        room_type_id: param
      });
    };
  
    return (
      <>
        {msg}
        <DataTable
          title="Room Manager"
          section="room"
          userRole="user"
          columns={columns}
          insertable={true}
          editable={true}
          url={process.env.REACT_APP_DEFAULT_API}
          messageHandler={messageHandler}
          specialData={specialData}
        ></DataTable>
      </>
    );
}

export default RoomManagerContainer;
