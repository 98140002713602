import React, { useState, useEffect } from "react";
import MaterialTable from 'material-table'
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import SaveAlt from "@material-ui/icons/SaveAlt";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Add from "@material-ui/icons/Add";
import Check from "@material-ui/icons/Check";
import FilterList from "@material-ui/icons/FilterList";
import Remove from "@material-ui/icons/Remove";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import ClearIcon from "@material-ui/icons/Clear";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { forwardRef } from "react";

const Table = (props) =>{
    //props:
    //export, search, editable, insertable,delteable,title,columns,detailPanel, data, components
    return (
        <MaterialTable
          title={props.title}
          columns={props.columns}
          data={props.data}
          isLoading={props.isLoad}
          detailPanel={props.detailPanel}
          components={props.components}
          onRowClick={props.detail && ((event, rowData, togglePanel) => togglePanel())}  
          icons={{
            Add: forwardRef((props, ref) => <Add {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => (
              <ClearIcon {...props} ref={ref} />
            )),
            Clear: forwardRef((props, ref) => <ClearIcon {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => (
              <FilterList {...props} ref={ref} />
            )),
            FirstPage: forwardRef((props, ref) => (
              <FirstPage {...props} ref={ref} />
            )),
            LastPage: forwardRef((props, ref) => (
              <LastPage {...props} ref={ref} />
            )),
            NextPage: forwardRef((props, ref) => (
              <ChevronRight {...props} ref={ref} />
            )),
            PreviousPage: forwardRef((props, ref) => (
              <ChevronLeft {...props} ref={ref} />
            )),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => (
              <Remove {...props} ref={ref} />
            )),
            ViewColumn: forwardRef((props, ref) => (
              <ViewColumn {...props} ref={ref} />
            )),
            DetailPanel: forwardRef((props, ref) => (
              <ChevronRight {...props} ref={ref} />
            )),
            SortArrow: forwardRef((props, ref) => (
              <KeyboardArrowUpIcon {...props} ref={ref} />
            ))
          }}          
          editable={{
            onRowAdd: props.insertable && (newData => props.insertHandler(newData)),
            onRowUpdate: props.editable && (newData => props.updateHandler(newData)),
            onRowDelete: props.deleteable && (oldData => props.deleteHandler(oldData))
          }}
          
          options={{
            actionsColumnIndex: -1,
            exportButton: props.export,
            search: props.search,
            filtering: props.filtering,
          }}
        />
    )
}
export default Table;