import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Button,
  Typography,
  Divider
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TitleIcon from "@material-ui/icons/Title";
import Axios from "axios";
import Loading from "../../../components/UI/Loading/Loading";
import { useHistory } from "react-router-dom";
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'; 

const DocumentationDetails = props => {
  const [data, setData] = useState(null);
  const [isLoad, setIsLoad] = useState(false);
  const [html, setHtml] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (data === null) {
      getDocumentDetails();
    } else if (data !== null) {
      const rawData = JSON.parse(data.content);
  
      const cfg = {};
      let converter = new QuillDeltaToHtmlConverter(rawData.ops, cfg);
      
      const html = converter.convert(); 
      setHtml(html)
    }
  }, [data]);

  const getDocumentDetails = async () => {
    setIsLoad(true);
    try {
      let newData = await Axios.get(
        `${props.url}${props.userRole}/${props.section}/get/${props.match.params.slug}`,
        {
          headers: { Authorization: `jwt ${props.userCookies}` }
        }
      );
      setData(newData.data[0]);
    } catch (e) {
      props.messageHandler("Failed to get documentation", "error", true);
      setData([]);
    }

    setIsLoad(false);
  };

  function createMarkup() {
    return { __html: html };
  }

  return (
    <>
      {isLoad ? <Loading /> : ""}
      <Card variant="outlined">
        <CardContent>
          <Typography color="textPrimary" gutterBottom>
            {data != null && data.title}
          </Typography>
          <Divider style={{ marginBottom: "10px" }} />
          <div dangerouslySetInnerHTML={createMarkup()} />
        </CardContent>
        <Button
          size="medium"
          style={{ marginTop: "30px" }}
          color="secondary"
          onClick={e => history.push(`/home/documentation`)}
        >
          <ArrowBackIcon />
          Back
        </Button>
      </Card>
    </>
  );
};

export default DocumentationDetails;
