import React, { useState, useEffect } from "react";
import Message from "../../../../components/UI/Message/Message";
import useGlobal from "../../../../components/Hooks/useGlobal";import Axios from 'axios';
import Loading from "../../../../components/UI/Loading/Loading";
import { Paper, Button, Divider, Grid } from "@material-ui/core";
import Table from "../../../../components/UI/Table/Table";
import classes from "./MediaManager.module.css";
import { transformDate } from '../../../../components/Helper/DateHelper';
import { TablePagination } from '@material-ui/core';

const MediaManager = props => {
    
    const [pageNumber, setPageNumber] = useState(0); // start from 0, 0 = first page
    const [dataCount, setDataCount] = useState(0);
    const [media, setMedia] = useState([]);
    const [globalState, globalActions] = useGlobal();
    const [openModal, setOpenModal] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const [message, setMessage] = useState({
        text: "",
        type: "",
        status: false
    });
    const [firstLoad, setFirstLoad] = useState(true);

    const components = {
        Pagination: props => (
          <TablePagination
            {...props}
            rowsPerPageOptions={[]}
            rowsPerPage={5}
            count={dataCount}
            page={pageNumber}
            onChangePage={(e, page) =>
              {
                setPageNumber(page)
                fetchMedia(page)
              }}
           
          />
        ),
  
      }
    const columns = [
      { 
        title: 'Uploaded From',
        field: 'appName',
      },
      
      { 
        title: 'Description',
        field: 'description',
      },
      { 
        title: 'Created At', 
        field: 'createdAt',
        type: 'datetime',
        render: rowData =>{
          return(
            transformDate(new Date(rowData.createdAt))
          )
          
        },
        cellStyle: {
          width: '30%',
        },
        headerStyle: {
          width: '30%',
        }
      },
    ]

    const messageHandler = (text, type, state) => {
        let newMessage = { ...message };
        newMessage.text = text;
        newMessage.type = type;
        newMessage.status = state;
        setMessage(newMessage);
    };

    let msg = message.status ? (
        <Message text={message.text} icon={message.type} handler={messageHandler} />
    ) : (
        ""
    );
    useEffect(() => {
        if(firstLoad){
            fetchMedia(pageNumber);
            setFirstLoad(false);
        }
    }, []);
    
    const fetchMedia = async (page) => {
        setIsLoad(true);
        try {
            let data = await Axios.get(
            `${process.env.REACT_APP_MEDIA_API}media/all/${page}`,
            {
                headers: globalState.httpReqHeaders
            }
            );
            let payload = data.data.payload;
            setMedia(payload);
            setDataCount(data.data.dataCount);
        } catch (e) {
            messageHandler("Failed to load data", "error", true);
            setMedia([]);
        }
        setIsLoad(false);
    };

    const showMedia = (rowData) =>{
        if((rowData.mimeType).includes("image")){
            return (
                <img src={`${process.env.REACT_APP_MEDIA}s/${rowData.name}`} className={classes.Image}/>
            )
        }
       
    }

    return(
        <>
            {msg}
            {isLoad ? <Loading /> : ""}
            <Paper elevation={0} className={classes.Paper}>
            
            <Table
            components={components}
            columns={columns}
            data={media}        
            detailPanel={rowData => {
                return (
                    <div className={classes.DetailPanel}>
                        <h1 className={classes.Title}>{rowData.appName + ' - ' + rowData.name}</h1>
                        <h5 className={classes.DateColor}>
                        {
                            transformDate(new Date(rowData.createdAt))
                        }
                        </h5>
                        <Divider/>
                        <br/>
                        <h3>{rowData.description}</h3>
                        <p></p>
                        <Divider/>
                        <br/>
                            {showMedia(rowData)}
                        <Divider/>
                        <br/>       
                        
                    </div>
                )}}
            title="Media"
            />
            </Paper>
        </>
        
    )
}

export default MediaManager;