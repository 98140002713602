import React, { useState, useEffect } from "react";
import Tabular from "../../../../components/UI/Tabular/Tabular";
import RoleManager from "./RoleManager/RoleManager";
import Message from "../../../../components/UI/Message/Message";
import OrganizationManager from "./OrganizationManager/OrganizationManager";
import AccountManager from "./AccountManager/AccountManager";
import RegisterContainer from "./AccountManager/RegisterContainer";

const UserManager = props => {
  const [selectedMenu, setSelectedMenu] = useState(0);
  const [message, setMessage] = useState({
    text: "",
    type: "",
    status: false
  });

  const tabMenu = ["User", "Role", "Organization"];

  const messageHandler = (text, type, state) => {
    let newMessage = { ...message };
    newMessage.text = text;
    newMessage.type = type;
    newMessage.status = state;
    setMessage(newMessage);
  };

  let msg = message.status ? (
    <Message text={message.text} icon={message.type} handler={messageHandler} />
  ) : (
    ""
  );

  const renderBody = () => {
    if (selectedMenu == 0) {
      return (
        <AccountManager
          {...props}
          messageHandler={messageHandler}
          setSelectedMenu={setSelectedMenu}
          insertFreeAction={true}
          url={process.env.REACT_APP_DEFAULT_API}
        ></AccountManager>
      );
    } else if (selectedMenu == 1) {
      return (
        <RoleManager
          {...props}
          messageHandler={messageHandler}
          url={process.env.REACT_APP_DEFAULT_API}
        ></RoleManager>
      );
    } else if (selectedMenu == 2) {
      return (
        <OrganizationManager
          {...props}
          messageHandler={messageHandler}
          url={process.env.REACT_APP_DEFAULT_API}
        ></OrganizationManager>
      );
    } else if (selectedMenu == 3) {
      return (
        <RegisterContainer
          url={process.env.REACT_APP_DEFAULT_API}
          {...props}
          setSelectedMenu={setSelectedMenu}
        />
      );
    }
  };

  return (
    <>
      {msg}
      <Tabular tabMenu={tabMenu} setSelectedMenu={setSelectedMenu} />
      {renderBody()}
    </>
  );
};

export default UserManager;
