import React from "react";
import {
  Card,
  Typography,
  Divider,
  CardContent,
  Grid,
  TextField,
  Button,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import FaceIcon from "@material-ui/icons/Face";
import LockIcon from "@material-ui/icons/Lock";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ComboBox from "../../../../../../components/UI/ComboBox/ComboBox";

const RegisterForm = props => {
  //pilih role dan organizations
  const setOrganization = id => {
    props.setFormDataHandler("organization", id);
  };
  const setRole = id => {
    props.setFormDataHandler("role", id);
  };

  return (
    <>
      <Card variant="outlined">
        <CardContent>
          <Typography color="textSecondary" gutterBottom>
            Insert new user
          </Typography>
          <Divider style={{ marginBottom: "10px" }} />
          <form method="post" onSubmit={props.onClickSubmit}>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item>
                <FaceIcon />
              </Grid>
              <Grid item md={true} sm={true} xs={true}>
                <TextField
                  id="username"
                  label="Username"
                  type="text"
                  fullWidth
                  autoFocus
                  required
                  onChange={e => {
                    props.setFormDataHandler("username", e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item>
                <LockIcon />
              </Grid>
              <Grid item md={true} sm={true} xs={true}>
                <TextField
                  id="password"
                  label="Password"
                  type="Password"
                  fullWidth
                  required
                  onChange={e => {
                    props.setFormDataHandler("password", e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item>
                <LockIcon style={{ top: 10 }} />
              </Grid>
              <Grid item md={true} sm={true} xs={true}>
                <ComboBox
                  {...props}
                  section="organization"
                  userRole="admin"
                  userCookies={props.userCookies}
                  label="organizationName"
                  title="Organization"
                  setSpecialData={setOrganization}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item>
                <LockIcon style={{ top: 10 }} />
              </Grid>
              <Grid item md={true} sm={true} xs={true}>
                <ComboBox
                  {...props}
                  section="role"
                  userRole="admin"
                  userCookies={props.userCookies}
                  label="roleName"
                  title="Role"
                  setSpecialData={setRole}
                />
              </Grid>
            </Grid>
            <Grid container justify="center" style={{ marginTop: "20px" }}>
              <Button
                variant="outlined"
                color="primary"
                style={{ textTransform: "none" }}
                type="submit"
                fullWidth
              >
                Insert
              </Button>
            </Grid>
          </form>
        </CardContent>
            <Button
              size="medium"
              style={{ marginTop: "30px" }}
              color="secondary"
              onClick={e => props.setSelectedMenu(0)}
            >
              <ArrowBackIcon />
              Back
            </Button>
      </Card>
    </>
  );
};

export default RegisterForm;
