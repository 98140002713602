import React, { useState, useEffect } from "react";
import { Paper, Button, Divider, Grid } from "@material-ui/core";
import classes from "./ScheduleList.module.css";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import ScheduleForm from "./ScheduleForm/ScheduleForm";
import Axios from 'axios';
import Loading from "../../../../components/UI/Loading/Loading";
import Table from "../../../../components/UI/Table/Table";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';

const ScheduleList = (props) => {

    const [events, setEvents] = useState([]);
    const [isLoad, setIsLoad] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    
    useEffect(() => {
      if(events.length === 0){
        fetchSchedule();
      }
      if (people.length === 0) {
        fetchPeopleData();
      }
      if(group.length === 0){
        fetchGroupData();
      }
    }, []);

    
  const [people, setPeople] = useState([]);
  const [group, setGroup] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [postData, setPostData] = useState({
    id:'',
    title : '',
    date : new Date(),
    users: [],
    groups: [],
  });

  const postDataHandler = (type, data) =>{
    const prevData = {...postData};
    if(type === 'title'){
      prevData.title = data;
    }else if(type === 'date'){
      prevData.date = data;
    }else if(type === 'users'){
      prevData.users = data;
    }else if(type === 'groups'){
      prevData.groups = data;
    }
    setPostData(prevData);
  }

  const submitHandler = (evt) =>{
    if(!isEdit){
      postSchedule(evt)
    }else{
      updateSchedule(evt)
    }
  }


  const updateSchedule = async(evt) =>{
    evt.preventDefault();
    setIsLoad(true);
    try {
      Axios.patch(
        `${process.env.REACT_APP_SCHEDULER_PROJECT_API}user/schedule/update`,
        postData,
        {
          headers: props.globalState.httpReqHeaders
        }
      ).then((res)=>{
        if(!res.data.status){
          props.messageHandler(
            "Error: " +
            res.data.message,
            "error",
            true
          );
          handleClose();
          return;
        }
        props.messageHandler("Schedule updated!", "success", true);
        handleClose();
        fetchSchedule();
      }).catch(error => {
        if (!error.response) {
          props.messageHandler(
            "Unexpected error, please contact the administrator",
            "error",
            true
          );
        } else {
          props.messageHandler(
            "Error (" +
              error.response.status +
              "): " +
              error.response.data.message,
            "error",
            true
          );
      }
      })
    
    } catch (error) {
      props.messageHandler(
        "Unexpected error, please contact the administrator",
        "error",
        true
      );
    } finally{
      setIsLoad(false);
    }
  }

  const postSchedule = async(evt) =>{
    evt.preventDefault();
    setIsLoad(true);
    try {
      Axios.post(
        `${process.env.REACT_APP_SCHEDULER_PROJECT_API}user/schedule/add`,
        postData,
        {
          headers: props.globalState.httpReqHeaders
        }
      ).then((res)=>{
        props.messageHandler("New Schedule Added!", "success", true);
        handleClose();
        fetchSchedule();
      }).catch(error => {
        if (!error.response) {
          props.messageHandler(
            "Unexpected error, please contact the administrator",
            "error",
            true
          );
        } else {
          props.messageHandler(
            "Error (" +
              error.response.status +
              "): " +
              error.response.data.message,
            "error",
            true
          );
      }
      })
    
    } catch (error) {
      props.messageHandler(
        "Unexpected error, please contact the administrator",
        "error",
        true
      );
    } finally{
      setIsLoad(false);
    }
  }

  const deleteSchedule = async(data) =>{    
    setIsLoad(true);
    Axios.delete(
      `${process.env.REACT_APP_SCHEDULER_PROJECT_API}user/schedule/delete`,
    {
      headers: props.globalState.httpReqHeaders,
      data: data
    })
      .then((res) => {
        if(!res.data.status){
          props.messageHandler(
            "Error: " +
            res.data.message,
            "error",
            true
          );
          setIsLoad(false);
          handleClose();
          return;
        }
        props.messageHandler("Schedule has been canceled", "success", true);
        fetchSchedule();
        setIsLoad(false);
      })
      .catch(error => {
        props.messageHandler(
          "Error (" +
            error.response.status +
            "): " +
            "Unexpected error, please contact the administrator",
          "error",
          true
        );
        setIsLoad(false);
      });
  }

  const fetchPeopleData = async () => {
    setIsLoad(true);
    try {
      let data = await Axios.get(
        `${process.env.REACT_APP_DEFAULT_API}${props.userData.Role.roleName}/user/all`,
        {
          headers: props.globalState.httpReqHeaders
        }
      );
      let payload = data.data.payload;
      payload.map((item, index)=>{
        item.title = item.username
      })
      setPeople(payload);
    } catch (e) {
      props.messageHandler("Failed to load data", "error", true);
      setPeople([]);
    }
    setIsLoad(false);
  };

  
  const fetchGroupData = async () => {
    setIsLoad(true);
    try {
      let data = await Axios.get(
        `${process.env.REACT_APP_DEFAULT_API}${props.userData.Role.roleName}/organization/all`,
        {
          headers: { Authorization: `jwt ${props.userCookies}` }
        }
      );
      let payload = data.data.payload;
      payload.map((item, index)=>{
        item.title = item.organizationName
      })
      setGroup(payload);
    } catch (e) {
      props.messageHandler("Failed to load data", "error", true);
      setGroup([]);
    }
    setIsLoad(false);
  };  


  const fetchSchedule = async () => {
      setIsLoad(true);
      try {
          let data = await Axios.get(
          `${process.env.REACT_APP_SCHEDULER_PROJECT_API}user/schedule/next`,
          {
              headers: { Authorization: `jwt ${props.userCookies}` }
          }
          );
          let payload = data.data.payload;
          setEvents(payload);
      } catch (e) {
          props.messageHandler("Failed to load data", "error", true);
          setEvents([]);
      }
      setIsLoad(false);
  };

    const handleUpdate = (rowData) =>{      
      const newPostData = postData;
      newPostData.id = rowData._id;
      newPostData.title = rowData.text;
      newPostData.date = rowData.ts;
      newPostData.users = rowData.users;
      newPostData.groups = rowData.groups;
      setPostData(newPostData);
      setIsEdit(true);
      setOpenModal(true);
    }

    const handleInsert = () =>{      
      const newPostData = postData;
      newPostData.title = '';
      newPostData.date = new Date();
      newPostData.users = [];
      newPostData.groups = [];
      setPostData(newPostData);
      setIsEdit(false);
      setOpenModal(true);
    }

    const handleClose = () => {
      setOpenModal(false);
    };


    const renderForm = () => {
        if (openModal) {
            return <ScheduleForm {...props}
            postData={postData}
            messageHandler={props.messageHandler}
            people={people}
            dataHandler={postDataHandler}
            group={group}        
            onClickSubmit={submitHandler}
            handleClose={handleClose} 
            openModal={openModal} 
            isEdit={isEdit}
            time={postData.time}/>;
        } 
    
    };

    const ownerButton = (rowData) =>{
      return(        
        <Grid item>
          <span className={classes.Button}>
            <Button variant="outlined" color="primary" onClick={
              ()=>{
                handleUpdate(rowData)
              }
            }>
              <EditIcon/>&nbsp;Edit Schedule
            </Button>
          </span>
          <span className={classes.Button}>
            <Button variant="outlined" color="secondary" onClick={()=>deleteSchedule(rowData)}>
              <CancelIcon/>&nbsp;Cancel Schedule
            </Button>
          </span>
        </Grid>
      )
    }

    return (
        <>
          {/* {isLoad ? <Loading /> : ""} */}
          <Paper elevation={0} className={classes.Paper}>
          
            <Table
              columns={props.columns}
              isLoad={isLoad}
              detail={true}
              data={events}        
              detailPanel={rowData => {
                return (
                  <div className={classes.DetailPanel}>
                    <h1 className={classes.Title}>{rowData.text}</h1>
                    <h5 className={classes.DateColor}>
                      {
                        props.transformDate(new Date(rowData.ts))
                      }
                    </h5>
                    <Divider/>
                    <br/>
                    <h3>Participants:</h3>
                    
                    <List dense>
                    {rowData.users.map(user =>{
                        return (
                        <ListItem key={user.id} button>            
                            <ListItemAvatar>
                              <Avatar
                                alt={`Avatar `}
                                src={`/static/`}
                              />
                            </ListItemAvatar>
                            <ListItemText id={user.id} primary={user.username} />
                          </ListItem>)
                      })}
                    </List>
                    <List dense>
                    {rowData.groups.map(group =>{
                        return (
                        <ListItem key={group.id} button>       
                          <ListItemAvatar>
                            <Avatar
                              alt={`Avatar `}
                              src={`/static/`}
                            />
                          </ListItemAvatar>                              
                            <ListItemText id={group.id} primary={'Group - ' + group.organizationName} />
                          </ListItem>)
                      })}
                    </List>
                    <Grid
                      justify="space-between" // Add it here :)
                      container 
                      spacing={24}
                    >
                      <Grid item>
                      </Grid>
                      {rowData.owner == props.userData.id && ownerButton(rowData)}
                    </Grid>
                  </div>
                    )
                  }
                }
              title="Upcoming Schedule"
            />
            {renderForm()}
            <Button
                variant="outlined"
                size="medium"
                color="primary"
                onClick={handleInsert}
            > 
              <br/>
                <AddCircleOutlineOutlinedIcon />
                &nbsp;Add
            </Button>
          </Paper>
        </>
    );
};

export default ScheduleList;
