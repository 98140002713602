import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useCookies } from 'react-cookie';

const LogOut = (props) => {

    const [cookies, setCookie, removeCookie] = useCookies(['_u']);
    const [isLogout, setIsLogout] = useState(false);

    useEffect(() => {        
        const logOut =  () => {
            removeCookie('_u', {
                path: '/'
            });
            setIsLogout(true);
        }
        logOut();

    }, []);

    const render = isLogout ? <Redirect to="/"/> : ''
    
    return (
        <>
            {render}
        </>
    );
}

export default LogOut;