import React, { useEffect, useState , useRef} from "react";
import {
    Card,
    CardContent,
    Button,
    Typography,
    Divider
  } from "@material-ui/core";
  import useGlobal from "../../../../../components/Hooks/useGlobal";
import { useHistory } from "react-router-dom";
import axios from "axios";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PCStatus from "./PCStatus";
  
const RoomDetails = (props) =>{
    const slug = props.match.params.slug;
    const roomSlug = props.match.params.roomSlug;
    const [roomData, setRoomData] = useState(null);
    const [patchData, setPatchData] = useState(null);
    const [isLoad, setIsLoad] = useState(false);
    const history = useHistory();
    const [globalState, globalActions] = useGlobal();

    const getPatchDetails = async () => {
        setIsLoad(true);
        try {
        let newData = await axios.get(
            `${process.env.REACT_APP_DOCUMENTATION_API}user/patch/get/${props.match.params.slug}`,
            {
                headers: globalState.httpReqHeaders
            }
        );
        setPatchData(newData.data.payload);
        } catch (e) {
            props.messageHandler("Failed to get patch details", "error", true);
            setPatchData([]);
        }
        setIsLoad(false);
    };
    
    const getRoomDetails = async () => {
        setIsLoad(true);
        try {
        let newData = await axios.get(
            `${process.env.REACT_APP_DEFAULT_API}user/room/${roomSlug}`,
            {
                headers: globalState.httpReqHeaders
            }
        );
        setRoomData(newData.data.payload);
        } catch (e) {
            props.messageHandler("Failed to get patch details", "error", true);
            setRoomData([]);
        }
        setIsLoad(false);
    };

    useEffect(() => {
        getPatchDetails();
        getRoomDetails();
    }, []);

    return (
        <>
        <Card variant="outlined">
            <CardContent>
                    
                <Typography color="textPrimary" variant='h5'> 
                    {patchData && patchData.title}
                </Typography>
                <Typography color="textPrimary" gutterBottom> 
                    {roomData && roomData.room_code} ({roomData && roomData.room_ip}.0)
                </Typography>
                <div>
                {roomData && [...Array(33)].map((x, i) =>
                    <PCStatus key={i} base={roomData && roomData.room_ip} ip={i+1} slug={slug}/>
                )}

                </div>
                <Button
                    size="medium"
                    style={{ marginTop: "30px" }}
                    color="primary"
                    variant="outlined"
                    onClick={()=>{
                        {roomData && [...Array(33)].map((x, i) =>{                            
                            globalState.socket.emit(`patch`, `${roomData.room_ip}.${i+101}`, slug, data =>{
                                console.log(data);
                            })
                        }
                        
                        )}
                    }}
                >
                    Run All
                </Button>
            </CardContent>
            <Button
                size="medium"
                style={{ marginTop: "30px" }}
                color="secondary"
                onClick={e => history.push(`/home/admin/patch-manager/${slug}`)}
            >
            <ArrowBackIcon />
            Back
            </Button>
        </Card>
        </>
    );
}

export default RoomDetails;
