import React, { useState, useEffect } from "react";
import DataTable from "../../../components/UI/DataTable/DataTable";
import { useHistory } from "react-router-dom";

const DocumentationLists = props => {
  const history = useHistory();
  const [columns, setColumns] = useState([
    { title: "Title", field: "title" },
    { title: "Writter", field: "creator" },
  ]);

  const detailHandler = slug => {
    history.push(`/home/documentation/${slug}`)
  }

  return (
    <>
      <DataTable
        {...props}
        section="documentations"
        userRole="user"
        columns={columns}
        url={process.env.REACT_APP_DOCUMENTATION_API}
        isSlug={true}
        detailAction={true}
        updateFreeAction={true}
        detailHandler={detailHandler}
	title="Documentation lists"
      ></DataTable>
    </>
  );
};

export default DocumentationLists;
