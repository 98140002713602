import React from 'react';
import { Paper, withStyles, Grid, TextField, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import classes from './LoginForm.module.css';
const LoginForm = (props) =>{

    return (
        <form action="#" method="post" onSubmit={props.onClickSubmit}>          
            <Paper className={classes.padding + " " + classes.card + ", Login"}>
                <div className={classes.margin}>
                    <h1>Sign in</h1>
                    <Grid container spacing={8} alignItems="flex-end">
                        
                        <Grid item md={true} sm={true} xs={true}>
                            <TextField id="username" label="Username" type="text" fullWidth autoFocus required onChange={(e)=>{props.setFormDataHandler("username", e.target.value)}} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={8} alignItems="flex-end">                    
                        <Grid item md={true} sm={true} xs={true}>
                            <TextField id="password" label="Password" type="password" fullWidth required onChange={(e)=>{props.setFormDataHandler("password", e.target.value)}}/>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" justify="space-between">
                        <Grid item>
                            <FormControlLabel control={
                                <Checkbox
                                    color="primary"
                                    onChange={(e)=>{props.setFormDataHandler("remember", e.target.value)}}
                                />
                            } label="Remember me" />
                        </Grid>
                        <Grid item>
                            <Button disableFocusRipple disableRipple style={{ textTransform: "none" }} variant="text" color="primary">Forgot password ?</Button>
                        </Grid>
                    </Grid>
                    <Grid container justify="center" style={{ marginTop: '10px' }}>
                        <Button variant="outlined" color="primary" style={{ textTransform: "none" }} type="submit">Login</Button>
                    </Grid>
                </div>
            </Paper>
        </form>
    );
};

export default LoginForm