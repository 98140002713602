import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

export default function MultipleSelectTags(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        options={props.options}
        getOptionLabel={(option) => option.title}
        value={props.value}
        onChange={(event, value) => props.dataHandler(props.type, value)}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
              if(option != null){
                return(<Chip label={option.title} {...getTagProps({ index })}/>)
              }
            }
          )
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={props.label}
          />
        )}
      />
     
    </div>
  );
}
