import React, { useState, useEffect } from "react";
import DataTable from "../../../components/UI/DataTable/DataTable";
import { useHistory } from "react-router-dom";
import { Paper, Button, Link } from "@material-ui/core";
import Table from "../../../components/UI/Table/Table";
import classes from './PatchManager.module.scss'
import PatchForm from './PatchForm/PatchForm'
import Axios from 'axios';
import useGlobal from "../../../components/Hooks/useGlobal";

const PatchManager = (props) =>{
    const history = useHistory();
    const [globalState, globalActions] = useGlobal();
    const [isLoad, setIsLoad] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [postData, setPostData] = useState({
      id:'',
      title : '',
      documentation: '',
    });
    const [documentations, setDocumentations] = useState([]);
    const [patches, setPatches] = useState([]);
    const [columns, setColumns] = useState([]);

    const detailHandler = slug => {
      history.push(`/home/admin/patch-manager/${slug}`)
    }

    const handleInsert = () =>{      
      const newPostData = postData;
      newPostData.title = '';
      newPostData.documentation = '';
      setPostData(newPostData);
      setIsEdit(false);
      setOpenModal(true);
    }

    const handleClose = () => {
      setOpenModal(false);
    };
    
    const postDataHandler = (type, data) =>{
        const prevData = {...postData};
        if(type === 'title'){
            prevData.title = data;
        }else if(type === 'documentation'){
            prevData.documentation = data;
        }
        setPostData(prevData);
    }
      
    const postNewPatch = async(evt) =>{
      evt.preventDefault();
      setIsLoad(true);
      try {
        Axios.post(
          `${process.env.REACT_APP_DOCUMENTATION_API}user/patch/add`,
          postData,
          {
            headers: globalState.httpReqHeaders
          }
        ).then((res)=>{
          props.messageHandler("New Patch Added!", "success", true);
          handleClose();
          fetchPatches()
        }).catch(error => {
          if (!error.response) {
            props.messageHandler(
              "Unexpected error, please contact the administrator",
              "error",
              true
            );
          } else {
            props.messageHandler(
              "Error (" +
                error.response.status +
                "): " +
                error.response.data.message,
              "error",
              true
            );
        }
        })
      
      } catch (error) {
        props.messageHandler(
          "Unexpected error, please contact the administrator",
          "error",
          true
        );
      } finally{
        setIsLoad(false);
      }
    }

    const updatePatch = async(evt) =>{
      // evt.preventDefault();
      setIsLoad(true);
      try {
        Axios.patch(
          `${process.env.REACT_APP_DOCUMENTATION_API}user/patch`,
          evt,
          {
            headers: globalState.httpReqHeaders
          }
        ).then((res)=>{
          props.messageHandler("Patch updated!", "success", true);
          handleClose();
          fetchPatches();
        }).catch(error => {
          if (!error.response) {
            props.messageHandler(
              "Unexpected error, please contact the administrator",
              "error",
              true
            );
          } else {
            props.messageHandler(
              "Error (" +
                error.response.status +
                "): " +
                error.response.data.message,
              "error",
              true
            );
        }
        })
      
      } catch (error) {
        props.messageHandler(
          "Unexpected error, please contact the administrator",
          "error",
          true
        );
      } finally{
        setIsLoad(false);
      }
    }

    const submitHandler = (evt) =>{
        if(!isEdit){
            postNewPatch(evt)
        }else{
            // updateSchedule(evt)
        }
    }

    const renderForm = () => {
        if (openModal) {
            return <PatchForm {...props}
            postData={postData}
            documentations={documentations}
            dataHandler={postDataHandler}
            onClickSubmit={submitHandler}
            handleClose={handleClose} 
            openModal={openModal} 
            isEdit={isEdit}
            />;
        } 
    
    };    

    const fetchPatches = async () => {
        setIsLoad(true);
        try {
            let data = await Axios.get(
            `${process.env.REACT_APP_DOCUMENTATION_API}user/patch/all`,
            {
                headers: globalState.httpReqHeaders
            }
            );
            let payload = data.data.payload;
            setPatches(payload);
        } catch (e) {
            console.log(e)
            props.messageHandler("Failed to load data", "error", true);
            setPatches([]);
        }
        setIsLoad(false);
    };

    useEffect(() => {                
        if(documentations.length === 0){
            fetchDocumentation()   
        }
        if(patches.length === 0){
            fetchPatches();
        }
    }, []);

    const fetchDocumentation = async () => {
        setIsLoad(true);
        try {
            let data = await Axios.get(
            `${process.env.REACT_APP_DOCUMENTATION_API}user/documentations/all`,
            {
                headers: globalState.httpReqHeaders
            }
            );
            let payload = data.data.payload;
            
            setDocumentations(payload);  
            //
        } catch (e) {
            props.messageHandler("Failed to load data", "error", true);
            setDocumentations([]);  
        }
        setIsLoad(false);
    };
    useEffect(() => {
      const options={};
      documentations.map(doc => {
        const { slug, title } = doc;
        options[ slug ] = title
      })
      setColumns([
        { title: "Title", field: "title", editable: 'onUpdate' },
        { title: "Creator", field: "creator", editable: 'never' },
        { 
            title: "Documentation", 
            field: "documentationSlug",
            lookup: options,
            render: rowData =>{
              return(
                  <Link href={`/home/documentation/${rowData.documentationSlug}`}>
                      See docs..
                  </Link>
              )
              
            },
  
        },
        { 
            title: "Status", 
            field: "status",
            editable: 'never', 
            render: rowData =>{
              return(
                  rowData.status ? "Patched" : "On progress"
              )
              
            },
  
        },
      ])
      
    }, [documentations]);

    return (
        <>
        <Paper elevation={0} className={classes.Paper}>
          
            <DataTable
            messageHandler={props.messageHandler}
            columns={columns}
            isLoad={isLoad}
            detail={false}
            data={patches}         
            editable={true}
            updateHandler={updatePatch}
            detailAction={true}
            isSlug={true}
            detailHandler={detailHandler}       
            title="Patch Lists"
            />
         
            {renderForm()}
            <Button
                className={classes.Button}
                variant="outlined"
                size="medium"
                color="primary"
                onClick={handleInsert}
            > Add New Patch </Button> 
        </Paper>
        </>
    )
}

export default PatchManager;