import React, { useState, useEffect } from "react";
import { Paper, Button, Divider, Grid } from "@material-ui/core";
import classes from "../ProjectManager.module.css";
import Table from "../../../../components/UI/Table/Table";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import useGlobal from "../../../../components/Hooks/useGlobal";
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { transformDate } from '../../../../components/Helper/DateHelper';
import SubProjectList from '../SubProjectList/SubProjectList';
import Axios from 'axios';

const ProjectList = (props)=>{
  const [globalState, globalActions] = useGlobal();
  const [isLoad, setIsLoad] = useState(false);
  
  const ownerButton = (rowData) =>{
    if(rowData.status != 'Canceled')
      return(        
        <Grid item>
          <span className={classes.Button}>
            <Button variant="outlined" color="primary" 
            onClick={
              ()=>{
                props.handleUpdate(rowData)
              }}
              >
              <EditIcon/>&nbsp;Edit Project
            </Button>
          </span>
          <span className={classes.Button}>
            <Button variant="outlined" color="secondary" 
            onClick={()=>props.cancelProject(rowData) }>
              <CancelIcon/>&nbsp;Cancel Project
            </Button>
          </span>
        </Grid>
      )
  }

    return(
        
        <Paper elevation={0} className={classes.Paper}>
          
        <Table
          columns={props.columns}
          detail={true}
          isLoad={props.isLoad}
          components={props.components}
          data={props.projects}      
          title="Project"
          detailPanel={rowData => {
            return (
              <div className={classes.DetailPanel}>
                <h1 className={classes.Title}>{rowData.schedule.text}</h1>
                <h5 className={classes.DateColor}>
                  {
                    props.transformDate(new Date(rowData.schedule.ts))
                  }
                </h5>
                <Divider/>
                <br/>
                <h3>Project Description: </h3>
                <p>{rowData.description}</p>
                <Divider/>
                <br/>
                <h3>Project Key:</h3>
                <h4 className={classes.Key}>{rowData.key} &nbsp;&nbsp;&nbsp;
                  <CopyToClipboard text={rowData.key}>
                    <FileCopyIcon className={classes.CopyButton} />
                  </CopyToClipboard>
                </h4>          
                <Divider/>
                <br/>   
                
                <SubProjectList data={rowData} {...props} />
                <br/>
                <Divider/>
                <br/>
                <h3>Participants:</h3>                
                <List dense>
                {rowData.schedule.users.map(user =>{
                    return (
                    <ListItem key={user.id} button>            
                        <ListItemAvatar>
                          <Avatar
                            alt={`Avatar `}
                            src={`/static/`}
                          />
                        </ListItemAvatar>
                        <ListItemText id={user.id} primary={user.username} />
                      </ListItem>)
                  })}
                </List>             
                <List dense>
                {rowData.schedule.groups.map(group =>{
                    return (
                    <ListItem key={group.id} button>       
                      <ListItemAvatar>
                        <Avatar
                          alt={`Avatar `}
                          src={`/static/`}
                        />
                      </ListItemAvatar>                              
                        <ListItemText id={group.id} primary={'Group - ' + group.organizationName} />
                      </ListItem>)
                  })}
                </List>
                
                <Grid
                      justify="space-between" // Add it here :)
                      container 
                      spacing={24}
                    >
                      <Grid item>
                      </Grid>

                      {rowData.schedule.owner == props.userData.id && ownerButton(rowData)}
                    </Grid>
              </div>
                )
              }
            }
        />
        </Paper>
    )
}

export default ProjectList