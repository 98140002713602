import React, { useEffect, useState } from "react";
import {
  Card,
  Grid,
  TextField,
  Typography,
  Divider,
  CardContent,
  Button
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TitleIcon from "@material-ui/icons/Title";
import Axios from "axios";
import Loading from "../../../components/UI/Loading/Loading";
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import TextEditor from "../../../components/UI/TextEditor/TextEditor";
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'; 

const UpdateDocumentation = props => {
  const [data, setData] = useState(null);
  const [isLoad, setIsLoad] = useState(false);
  const [html, setHtml] = useState(null);

  useEffect(() => {
    // if (data === null) {
    //   getDocumentDetails();
    // } else if (data !== null) {
    //   const rawData = JSON.parse(data.content);
    //   const contentState = convertFromRaw(rawData);
    //   setHtml(stateToHTML(contentState));
    //   props.detailHandler(data.id);
    //   props.setContent(data.content);
    //   props.setTitle(data.title);
    // }
    if (data === null) {
      getDocumentDetails();
    } else if (data !== null) {

      const rawData = JSON.parse(data.content);
  
      const cfg = {};
      let converter = new QuillDeltaToHtmlConverter(rawData.ops, cfg);
      
      const html = converter.convert(); 
      setHtml(html)
      props.slugHandler(data.slug);
      props.setContent(data.content);
      props.setTitle(data.title);
    }
  }, [data]);

  const getDocumentDetails = async () => {
    setIsLoad(true);
    try {
      let newData = await Axios.get(
        `${props.url}${props.userRole}/${props.section}/get/${props.slug}`,
        {
          headers: { Authorization: `jwt ${props.userCookies}` }
        }
      );
      setData(newData.data[0]);
      console.log(newData.data[0])
    } catch (e) {
      props.messageHandler("Failed to get documentation", "error", true);
      setData([]);
    }

    setIsLoad(false);
  };

  const createMarkup = () => {
    return { __html: html };
  };

  return (
    <>
      {isLoad ? <Loading /> : ""}
      <Card variant="outlined">
        <CardContent>
          <Typography color="textPrimary" gutterBottom>
            Update Documentation
          </Typography>
          <Divider style={{ marginBottom: "10px" }} />
          <form method="post" onSubmit={props.onClickSubmit}>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item>
                <TitleIcon />
              </Grid>
              <Grid item md={true} sm={true} xs={true}>
                <TextField
                  id="title"
                  label="Documentation Title"
                  type="text"
                  fullWidth
                  value={props.data != null && props.data.title}
                  autoFocus
                  required
                  onChange={e => {
                    props.setTitle(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              alignItems="flex-end"
              style={{ marginTop: "20px" }}
            >
              {html != null ? (
                <TextEditor
                  {...props}
                  setContent={props.setContent}
                  html={html != null ? html : ""}
                />
              ) : (
                ""
              )}
            </Grid>
            <Grid container justify="center" style={{ marginTop: "20px" }}>
              <Button
                variant="outlined"
                color="primary"
                style={{ textTransform: "none" }}
                type="submit"
                fullWidth
              >
                Update
              </Button>
            </Grid>
          </form>
        </CardContent>
        <Button
          size="medium"
          style={{ marginTop: "30px" }}
          color="secondary"
          onClick={e => {
            props.setSelectedMenu(0);
            props.detailHandler(null);
          }}
        >
          <ArrowBackIcon />
          Back
        </Button>
      </Card>
    </>
  );
};

export default UpdateDocumentation;
